import { Create } from '@modules/drawing/commands/create';
import { debounce } from '@modules/custom';

function isArrowKey(key) {
  return ['ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown'].includes(key);
}

function isSpecialKey(key) {
  return [
    'Alt', 'Backspace', 'CapsLock', 'Clear', 'Control', 'Delete', 'End', 'Enter', 'Escape',
    'Home', 'Meta', 'PageDown', 'PageUp', 'Shift', 'Tab'
  ].includes(key);
}

export class TextManager {
  constructor(tool) {
    this.tool = tool;
    this.group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    this.caret = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    this.clickPosition = { x: undefined, y: undefined };
    this.activeElement = undefined;
  }

  init() {
    this.save = debounce(this.save, 500);

    this.caret.setAttribute('width', 1);
    this.caret.setAttribute('height', 19);

    this.group.append(this.caret);
    this.tool.element.append(this.group);
    this.reset();

    return this;
  }

  reset() {
    this.activeElement = null;

    this.caret.setAttribute('y', 5);
    this.caret.removeAttribute('x');
    this.caret.classList.remove('caret-blink');
    this.caret.style.display = 'none';
  }

  pointerdown(e) {
    if (e.target.tagName === 'text') {
      this.tool.activePointerId = null;
      return;
    }

    this.clickPosition = { x: e.posX, y: e.posY };
    this.group.setAttribute('transform', `translate(${e.posX}, ${e.posY})`);
    this.reset();
  }

  pointerup() {
    const command = new Create(this.tool, this.getAnchorPosition());
    command.execute();
    this.tool.commands.push(command);

    this.activeElement = command.component.element;
    this.activeComponent = command.component;

    this.showCaret();
    this.charIndex = 0;
    this.lineIndex = 0;

    this.clickPosition = { x: undefined, y: undefined };
  }

  getAnchorPosition() {
    const transform = this.group.getAttribute('transform');
    const [x, y] = transform.match(/translate.*\((.+)\)/)[1].split(',');

    return { x: parseFloat(x), y: parseFloat(y) + 20 };
  }

  showCaret() {
    this.caret.classList.add('caret-blink');
    this.caret.style.display = 'inline';
  }

  updateCaret() {
    const text = this.activeElement.children[this.lineIndex];

    const x = this.charIndex > 0 ? text.getSubStringLength(0, this.charIndex) : 0;
    const y = this.lineIndex > 0 ? (19 * this.lineIndex) : 0;
    this.caret.setAttribute('x', x);
    this.caret.setAttribute('y', y + 5);
  }

  keydown(e) {
    if (!this.activeElement) return;

    if (e.key === ' ') e.preventDefault();

    const text = this.activeElement.children[this.lineIndex];
    if (isSpecialKey(e.key)) {
      this.handleSpecialKeys(e.key, text);
    } else if (isArrowKey(e.key)) {
      this.handleArrowKeys(e.key, text);
      e.preventDefault();
    } else if (!e.metaKey) {
      this.handleCharacter(e.key, text);
    }

    this.updateCaret();
    this.save();
  }

  handleCharacter(key, text) {
    text.textContent = (
      text.textContent.substring(0, this.charIndex) + key + text.textContent.substring(this.charIndex)
    );
    this.charIndex += 1;
  }

  handleSpecialKeys(key, text) {
    switch (key) {
      case 'Backspace':
        if (this.charIndex === 0 && this.lineIndex === 0) return;

        if (this.charIndex === 0) {
          this.charIndex = text.previousElementSibling.getNumberOfChars();
          this.activeComponent.removeLine(this.lineIndex);
          this.lineIndex -= 1;
        } else {
          text.textContent = text.textContent.slice(0, this.charIndex - 1) + text.textContent.slice(this.charIndex);
          this.charIndex -= 1;
        }
        break;
      case 'Enter':
        this.activeComponent.addLine(this.lineIndex + 1, text.textContent.substring(this.charIndex));
        text.textContent = text.textContent.substring(0, this.charIndex);

        this.lineIndex += 1;
        this.charIndex = 0;
        break;
      default:
        break;
    }
  }

  handleArrowKeys(key, text) {
    switch (key) {
      case 'ArrowLeft':
        if (this.charIndex <= 0 && text.previousElementSibling) {
          this.lineIndex -= 1;
          this.charIndex = text.previousElementSibling.getNumberOfChars();
        } else if (this.charIndex > 0) {
          this.charIndex -= 1;
        }
        break;
      case 'ArrowUp':
        if (text.previousElementSibling) {
          this.lineIndex -= 1;
          this.charIndex = Math.min.apply(null, [this.charIndex, text.previousElementSibling.getNumberOfChars()]);
        }
        break;
      case 'ArrowRight':
        if (this.charIndex >= text.getNumberOfChars() && text.nextElementSibling) {
          this.lineIndex += 1;
          this.charIndex = 0;
        } else if (this.charIndex < text.getNumberOfChars()) {
          this.charIndex += 1;
        }
        break;
      case 'ArrowDown':
        if (text.nextElementSibling) {
          this.lineIndex += 1;
          this.charIndex = Math.min.apply(null, [this.charIndex, text.nextElementSibling.getNumberOfChars()]);
        }
        break;
      default:
        break;
    }
  }

  save() {
    this.tool.dispatchEvent();
  }
}
