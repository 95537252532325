import onmount from 'onmount';
import Rails from '@rails/ujs';
import { serialize } from '@modules/custom';

onmount('[data-js-code-editor]', function () {
  const run = this.querySelector('[data-js-run-code]');
  const editorEl = this.querySelector('[data-js-ace-editor]');
  const status = this.querySelector('[data-js-status]');
  const { url, runUrl } = this.dataset;

  function seed() {
    return Math.floor(Math.random() * 10000);
  }

  function updateQuestion() {
    const script = editorEl.value;

    Rails.ajax({
      url,
      type: 'PUT',
      dataType: 'json',
      data: serialize({ question: { script } }),
      success: (data) => {
        const hasScript = (data.script.length > 0);
        const event = new CustomEvent('scriptUpdated');
        const redactorFields = document.querySelectorAll(`[data-js-redactor][data-question-id='${data.id}']`);

        redactorFields.forEach((field) => {
          field.setAttribute('data-question-has-script', hasScript);
          field.dispatchEvent(event);
        });
      },
      complete: (xhr) => {
        Rails.fire(document.body, 'ajax:complete', [xhr]);
      }
    });
  }

  function handleScriptChanges() {
    if (!editorEl.aceEditor) {
      setTimeout(handleScriptChanges, 50);
      return;
    }

    const editor = editorEl.aceEditor;
    let scriptChanged = false;

    editor.on('change', () => {
      scriptChanged = true;
    });

    editor.removeAllListeners('blur');
    editor.on('blur', () => {
      if (scriptChanged) {
        updateQuestion();
        scriptChanged = false;
      }
    });
  }

  function runCode() {
    const script = editorEl.value;
    if (!script.trim().length) return;

    Rails.disableElement(run);

    Rails.ajax({
      url: runUrl,
      type: 'POST',
      beforeSend(xhr, options) {
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        // Workaround: add options.data late to avoid Content-Type header to already being set in stone
        // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
        options.data = JSON.stringify({ script, seed: seed() });
        return true;
      },
      success: (data) => {
        status.innerText = data.message;
        if (editorEl.aceEditor) editorEl.aceEditor.focus();
      },
      error: (data) => {
        status.innerText = data.message;
        if (editorEl.aceEditor) editorEl.aceEditor.focus();
      },
      complete: () => {
        Rails.enableElement(run);
      }
    });
  }

  if (editorEl) handleScriptChanges();
  if (run) run.addEventListener('click', runCode);
  if (editorEl.value.trim().length) runCode();
});
