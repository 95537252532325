import onmount from 'onmount';
import { renderKatex } from '@vendor/katex';
import { blurHighlight } from '@modules/deprecated-highlight-worker';
import { showFormErrors } from '@modules/form-responses';

onmount('[data-js-highlight-comment]', function () {
  const form = this.querySelector('form');
  const cancel = this.querySelector('[data-js-cancel]');

  /* FUNCTIONS */
  function formSuccessListener(event) {
    const openForm = document.querySelector('[data-js-highlight-comment] form');
    if (event.target === form && !openForm) {
      blurHighlight();
    }
  }

  function formErrorListener(event) {
    if (event.target === form) showFormErrors(event);
  }

  function handleCancelClick() {
    const highlightCommentCard = this.closest('[data-js-highlight-comment-panel]');
    if (highlightCommentCard.querySelectorAll('form').length > 1) {
      this.style.display = 'none';
      form.remove();
    } else {
      blurHighlight();
    }
  }
  this.handleCancelClick = handleCancelClick.bind(this);

  /* EVENT HANDLERS */
  if (form) {
    form.addEventListener('ajax:success', formSuccessListener);
    form.addEventListener('ajax:error', formErrorListener);
  }
  if (cancel) cancel.addEventListener('click', this.handleCancelClick);

  /* INITIALIZATION */
  renderKatex(this);
});
