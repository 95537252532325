import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';

export default class extends Controller {
  static targets = ['highlight', 'poptip', 'menu', 'colormenu', 'delete'];

  clickOutside() {
    this.removePopToolTips();
  }

  removePopToolTips() {
    if (!this.hasPoptipTarget) return;

    this.poptipTarget.querySelector('div').classList.add('d-none');
    this.element.appendChild(this.poptipTarget);
    this.colormenuTarget.classList.remove('button-active');
    this.menuTarget.style.setProperty('display', 'none');
  }

  tooltipPlacementForHighlight(event) {
    this.poptipTarget.querySelector('div').style.transform = `translate(${event.clientX}px, ${event.clientY - 50}px)`;
  }

  setAttributesForTooltip(highlight) {
    const { highlightId, commentsCount, color } = highlight.dataset;
    const highlightsUrl = '/highlights/';

    const endpoint = Number(commentsCount) > 0 ? 'edit' : 'highlight_comments/new';
    this.poptipTarget.querySelector('a').setAttribute('href', `${highlightsUrl}${highlightId}/${endpoint}`);
    this.deleteTarget.setAttribute('href', `${highlightsUrl}${highlightId}`);
    this.menuTarget.querySelectorAll('a').forEach((element) => {
      element.setAttribute('href', encodeURI(`${highlightsUrl}${highlightId}?highlight[color]=${element.dataset.color}`));
      if (element.dataset.color === color) {
        element.querySelector('i').textContent = 'check_circle';
      } else {
        element.querySelector('i').textContent = 'circle';
      }
    });
    this.colormenuTarget.style.color = `var(--color-highlight-color-${color})`;
  }

  createTooltipForHighlight(event, highlight) {
    this.tooltipPlacementForHighlight(event);
    this.setAttributesForTooltip(highlight);
    this.element.insertAdjacentElement('afterbegin', this.poptipTarget);
    this.poptipTarget.querySelector('div').classList.remove('d-none');
  }

  openHighlightToolTip(event) {
    const { id } = event.params;
    this.removePopToolTips();
    const relatedHighlights = this.highlightTargets.filter(
      (highlight) => highlight.dataset.highlightId === id
    );

    this.createTooltipForHighlight(event, relatedHighlights[0]);
    useClickOutside(this, { element: this.poptipTarget, onlyVisible: false });
  }

  colorMenuClick() {
    if (!this.hasPoptipTarget) return;

    this.menuTarget.style.setProperty('display', 'flex', 'important');
    this.colormenuTarget.classList.add('button-active');
  }
}
