/* global I18n */

import snackbar from '@components/snackbar';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    content: { type: String, default: '' }
  };

  copy() {
    navigator.clipboard.writeText(this.contentValue);
    snackbar(I18n.t('js.general.successfully_copied'), false);
  }
}
