/* global I18n */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'text'];

  static values = {
    points: Number
  };

  update() {
    const percentage = (parseFloat(this.inputTarget.value) / 100);
    const subquestionPoints = parseFloat(this.pointsValue);

    if (Number.isNaN(percentage)) {
      this.textTarget.innerText = '';
    } else {
      const previewText = Number((percentage * subquestionPoints).toFixed(2));
      this.textTarget.innerText = I18n.t('js.questions.points', { count: previewText });
    }
  }
}
