/* global I18n */

import { ratio } from 'wcag-color';

function elementIsHidden(element) {
  return element.closest('[aria-hidden="true"], [hidden], [style*="display: none"]') != null;
}

function isVisible(element) {
  return !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
}

function isText(value) {
  return typeof value === 'string' && !!value.trim();
}

function accessibleText(node) {
  switch (node.nodeType) {
    case Node.ELEMENT_NODE:
      if (
        isText(node.getAttribute('alt')) ||
        isText(node.getAttribute('aria-label')) ||
        isText(node.getAttribute('title'))
      ) return true;
      for (let i = 0; i < node.childNodes.length; i += 1) {
        if (accessibleText(node.childNodes[i])) return true;
      }
      break;
    case Node.TEXT_NODE:
      return isText(node.data);
    default:
      return false;
  }
  return false;
}

function getBackground(node) {
  const styles = getComputedStyle(node);
  const bgColor = styles.backgroundColor;
  const bgImage = styles.backgroundImage;

  if (bgColor !== 'rgba(0, 0, 0, 0)' && bgColor !== 'transparent' && bgImage === 'none') {
    return bgColor;
  }
  if (bgImage !== 'none') {
    return 'image';
  }

  if (node.tagName === 'HTML') return 'rgb(255, 255, 255)';

  return getBackground(node.parentNode);
}

function errorSubclass(fn) {
  fn.prototype = new Error();
  fn.prototype.constructor = fn;
}

function ImageWithoutAltAttributeError(element) {
  this.name = 'ImageWithoutAltAttributeError';
  this.stack = new Error().stack;
  this.element = element;
  this.message = I18n.t('js.accessibility.image_without_alt');
}
errorSubclass(ImageWithoutAltAttributeError);

function ElementWithoutLabelError(element) {
  this.name = 'ElementWithoutLabelError';
  this.stack = new Error().stack;
  this.element = element;
  this.message = I18n.t('js.accessibility.element_without_label');
}
errorSubclass(ElementWithoutLabelError);

function LinkWithoutLabelOrRoleError(element) {
  this.name = 'LinkWithoutLabelOrRoleError';
  this.stack = new Error().stack;
  this.element = element;
  this.message = I18n.t('js.accessibility.link_without_label_or_role');
}
errorSubclass(LinkWithoutLabelOrRoleError);

function LabelMissingControlError(element) {
  this.name = 'LabelMissingControlError';
  this.stack = new Error().stack;
  this.element = element;
  this.message = I18n.t('js.accessibility.label_missing_control');
}
errorSubclass(LabelMissingControlError);

function InputMissingLabelError(element) {
  this.name = 'InputMissingLabelError';
  this.stack = new Error().stack;
  this.element = element;
  this.message = I18n.t('js.accessibility.input_missing_label');
}
errorSubclass(InputMissingLabelError);

function ButtonWithoutLabelError(element) {
  this.name = 'ButtonWithoutLabelError';
  this.stack = new Error().stack;
  this.element = element;
  this.message = I18n.t('js.accessibility.button_without_label');
}
errorSubclass(ButtonWithoutLabelError);

function ARIAAttributeMissingError(element, attr) {
  this.name = 'ARIAAttributeMissingError';
  this.stack = new Error().stack;
  this.element = element;
  this.message = `Missing ${attr} attribute`;
}
errorSubclass(ARIAAttributeMissingError);

function ContrastTooLowError(element) {
  this.name = 'ContrastTooLowError';
  this.stack = new Error().stack;
  this.element = element;
  this.message = I18n.t('js.accessibility.contrast_too_low');
}
errorSubclass(ContrastTooLowError);

export function scanForProblems(context, errorCallback, options) {
  const errors = {};

  function logError(err) {
    const { name } = err;

    errors[name] = errors[name] || [];
    errors[name].push(err);

    errorCallback(err);
  }

  context.querySelectorAll('img').forEach((img) => {
    if (!img.hasAttribute('alt')) {
      logError(new ImageWithoutAltAttributeError(img));
    }
  });

  context.querySelectorAll('a').forEach((a) => {
    if (a.hasAttribute('name') || elementIsHidden(a)) {
      return;
    }
    if (a.getAttribute('href') == null && a.getAttribute('role') !== 'button') {
      logError(new LinkWithoutLabelOrRoleError(a));
    } else if (!accessibleText(a)) {
      logError(new ElementWithoutLabelError(a));
    }
  });

  context.querySelectorAll('button').forEach((button) => {
    if (!elementIsHidden(button) && !accessibleText(button)) {
      logError(new ButtonWithoutLabelError(button));
    }
  });

  context.querySelectorAll('label').forEach((label) => {
    // In case label.control isn't supported by browser, find the control manually (IE)
    const control = label.control || document.getElementById(label.getAttribute('for')) || label.querySelector('input');

    if (!control && !elementIsHidden(label)) {
      logError(new LabelMissingControlError(label));
    }
  });

  context.querySelectorAll(
    'input[type=text], input[type=url], input[type=search], input[type=number], textarea'
  ).forEach((input) => {
    // In case input.labels isn't supported by browser, find the control manually (IE)
    const inputLabel = input.labels ?
      input.labels[0] :
      input.closest('label') || document.querySelector(`label[for="${input.id}"]`);
    if (!inputLabel && !elementIsHidden(input) && !input.hasAttribute('aria-label')) {
      logError(new InputMissingLabelError(input));
    }
  });

  const textTags = 'a, span, strong, strike, b, u, em, i, code, del, ins, samp, kbd,' +
                   'sup, sub, mark, var, cite, small, abbr pre, ul, ol, li, p, h1, h2,' +
                   'h3, h4, h5, h6,  dl, dt, dd, div, table, tbody, thead, tfoot, tr, th,' +
                   'td, blockquote, output, figcaption, figure, address, section, header,' +
                   'footer, aside, article, iframe';

  context.querySelectorAll(textTags).forEach((elem) => {
    // test if visible
    if (!isVisible(elem)) return;
    if (options && options.ignoreClass) {
      if (elem.closest(`.${options.ignoreClass}`)) return;
    }

    const textString = [].reduce.call(elem.childNodes, (a, b) => a + (b.nodeType === 3 ? b.textContent : ''), '');
    const text = textString.trim();

    if (text.length) {
      const background = getBackground(elem);

      // does element have a background image - needs to be manually reviewed
      if (background === 'image') return;

      const style = getComputedStyle(elem);
      const { color, fontWeight } = style;

      const contrastRatio = ratio(color, background);
      const fontSize = parseInt(style.fontSize, 10);

      if (elem.hasAttribute('data-js-hotspot-marker')) return;

      if ((fontSize >= 18) || (fontSize >= 14 && fontWeight >= 700)) {
        if (contrastRatio < 3) {
          logError(new ContrastTooLowError(elem));
        }
      } else if (contrastRatio < 4.5) {
        logError(new ContrastTooLowError(elem));
      }
    }
  });

  if (options && options.ariaPairs) {
    options.ariaPairs.forEach((selector) => {
      const ARIAAttrsRequired = options.ariaPairs[selector];
      context.querySelectorAll(selector).forEach((target) => {
        const missingAttrs = [];

        ARIAAttrsRequired.forEach((attr) => {
          if (!target.hasAttribute(attr)) missingAttrs.push(attr);
        });

        if (missingAttrs.length > 0) {
          logError(new ARIAAttributeMissingError(target, missingAttrs.join(', ')));
        }
      });
    });
  }

  return errors;
}
