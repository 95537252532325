/* global I18n, Channels */

import snackbar from '@components/snackbar';

export function lockSubmission() {
  if (!Channels.quizResponses) return;
  if (typeof Channels.quizResponses.lock === 'undefined') return;

  const { submissionId } = this.closest('[data-js-quiz-subquestion]').dataset;
  if (Channels.quizResponses.lock(submissionId)) {
    snackbar(I18n.t('js.quizzes.locked_by_another'));
  }
}

export function unlockSubmission() {
  if (!Channels.quizResponses) return;
  if (typeof Channels.quizResponses.unlock === 'undefined') return;

  const { submissionId } = this.closest('[data-js-quiz-subquestion]').dataset;
  Channels.quizResponses.unlock(submissionId);
}
