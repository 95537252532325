/* global I18n */

import onmount from 'onmount';
import Rails from '@rails/ujs';
import { renderKatex } from '@vendor/katex';

onmount('[data-js-none-above-comment-wrapper]', function () {
  const form = this.querySelector('[data-js-none-above-comment-form]');
  const textarea = this.querySelector('[data-js-redactor]');
  const comment = this.querySelector('[data-js-none-above-comment]');
  const show = this.querySelector('[data-js-show-none-above-comment]');
  const add = this.querySelector('[data-js-add-none-above-comment]');
  const edit = this.querySelector('[data-js-edit-none-above-comment]');
  const cancel = this.querySelector('[data-js-cancel-none-above-comment]');
  const destroy = this.querySelector('[data-js-delete-none-above-comment]');
  const suggestions = this.querySelector('[data-js-none-above-suggestions]');

  function handleAddClick() {
    this.classList.add('d-none');
    form.classList.remove('d-none');
    form.querySelector('[data-js-redactor]').redactor.editor.focus();
  }

  function handleEditClick() {
    show.classList.add('d-none');
    form.classList.remove('d-none');
  }

  function handleCancelClick() {
    form.classList.add('d-none');
    if (comment.getAttribute('data-persisted') === 'true') {
      show.classList.remove('d-none');
    } else {
      add.classList.remove('d-none');
    }
  }

  function handleDestroyClick() {
    if (this.hasAttribute('data-confirm')) return;

    show.classList.add('d-none');
    form.classList.add('d-none');
    if (suggestions) suggestions.classList.remove('d-none');
    textarea.redactor.source.setCode('');
    textarea.redactor.editor.app.broadcast('hardsync');
    Rails.fire(form, 'submit');
  }

  function handleFormSuccess() {
    form.classList.add('d-none');
    const content = textarea.redactor.source.getCode();
    comment.innerHTML = content;

    if (content === '<div></div>') {
      add.classList.remove('d-none');
      comment.setAttribute('data-persisted', 'false');
    } else {
      show.classList.remove('d-none');
      comment.setAttribute('data-persisted', 'true');
      if (suggestions) suggestions.classList.add('d-none');
    }

    renderKatex(comment);
    destroy.setAttribute('data-confirm', I18n.t('js.general.are_you_sure'));
  }

  add.addEventListener('click', handleAddClick);
  edit.addEventListener('click', handleEditClick);
  cancel.addEventListener('click', handleCancelClick);
  destroy.addEventListener('click', handleDestroyClick);
  form.addEventListener('ajax:success', handleFormSuccess);
});
