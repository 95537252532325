/* global myLazyLoad */

import onmount from 'onmount';
import hljs from 'highlight.js/lib/core';
import cpp from 'highlight.js/lib/languages/cpp';
import csharp from 'highlight.js/lib/languages/csharp';
import haskell from 'highlight.js/lib/languages/haskell';
import java from 'highlight.js/lib/languages/java';
import javascript from 'highlight.js/lib/languages/javascript';
import latex from 'highlight.js/lib/languages/latex';
import matlab from 'highlight.js/lib/languages/matlab';
import php from 'highlight.js/lib/languages/php';
import prolog from 'highlight.js/lib/languages/prolog';
import python from 'highlight.js/lib/languages/python';
import r from 'highlight.js/lib/languages/r';
import ruby from 'highlight.js/lib/languages/ruby';
import bash from 'highlight.js/lib/languages/bash';
import sql from 'highlight.js/lib/languages/sql';
import swift from 'highlight.js/lib/languages/swift';

onmount('[data-js-redactor-content]', function () {
  const self = this;
  const preTags = self.querySelectorAll('pre');

  if (typeof myLazyLoad !== 'undefined') myLazyLoad.update();

  if (preTags.length === 0) return;

  function dispatchHighlightjsEvent() {
    const highlightEvent = new CustomEvent('highlightjs:initialized');
    self.dispatchEvent(highlightEvent);
  }

  function highlightPreTags() {
    preTags.forEach((block) => {
      const elements = block.querySelectorAll('code');
      elements.forEach((element) => {
        if (!element.querySelector('input')) {
          hljs.highlightElement(element);
        }
      });

      block.querySelectorAll('.mdc-text-field').forEach((el) => {
        el.classList.add('mdc-text-field--white');
      });

      block.classList.add('hljs');
    });
  }

  function initHighlightjs() {
    hljs.registerLanguage('cpp', cpp);
    hljs.registerLanguage('csharp', csharp);
    hljs.registerLanguage('haskell', haskell);
    hljs.registerLanguage('java', java);
    hljs.registerLanguage('javascript', javascript);
    hljs.registerLanguage('latex', latex);
    hljs.registerLanguage('matlab', matlab);
    hljs.registerLanguage('php', php);
    hljs.registerLanguage('prolog', prolog);
    hljs.registerLanguage('python', python);
    hljs.registerLanguage('r', r);
    hljs.registerLanguage('ruby', ruby);
    hljs.registerLanguage('bash', bash);
    hljs.registerLanguage('sql', sql);
    hljs.registerLanguage('swift', swift);

    highlightPreTags();

    dispatchHighlightjsEvent();
  }

  try {
    initHighlightjs();
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
  }
});
