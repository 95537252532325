import onmount from 'onmount';
import Rails from '@rails/ujs';
import { serialize } from '@modules/custom';

onmount('[data-js-runner]', function () {
  const { assignmentId, submissionId } = this.dataset;
  const codeEl = this.querySelector('[data-js-code]');
  const supportedLanguages = JSON.parse(codeEl.dataset.supportedLanguages);
  const testableLanguages = JSON.parse(codeEl.dataset.testableLanguages);
  let language = codeEl.dataset.type;
  const button = this.querySelector('[data-js-run]');
  const testButton = this.querySelector('[data-js-test]');
  const consoleWrapper = this.querySelector('[data-js-console-wrapper]');
  let consoleEl = this.querySelector('[data-js-console]');

  function disableButton(element) {
    const replacement = element.getAttribute('data-custom-disable-with');
    if (replacement) {
      element.setAttribute('ujs:custom-enable-with', element.innerHTML);
      element.innerHTML = replacement;
    }
    element.disabled = true;
  }

  function enableButton(element) {
    const originalText = element.getAttribute('ujs:custom-enable-with');
    if (originalText) {
      element.innerHTML = originalText;
      element.removeAttribute('ujs:custom-enable-with');
    }
    element.disabled = false;
  }

  function makeRequest(params) {
    consoleEl.innerText = '';
    if (!params.code) {
      if (button) enableButton(button);
      if (testButton) enableButton(testButton);
      return;
    }
    consoleEl = document.querySelector(`#runner_frame_${submissionId}`);

    Rails.ajax({
      url: `${window.location.origin}/assignments/${assignmentId}/runner`,
      type: 'GET',
      data: serialize(params),
      success: (data) => {
        consoleEl.innerText = data;
      },
      complete: (xhr) => {
        Rails.fire(document.body, 'ajax:complete', [xhr]);
        if (button) enableButton(button);
        if (testButton) enableButton(testButton);
      }
    });
  }

  function run() {
    const params = {
      operation: 'run',
      code: codeEl.value,
      language,
      submission_id: submissionId
    };

    if (button) disableButton(button);
    makeRequest(params);
  }

  function grade() {
    const params = {
      operation: 'test',
      code: codeEl.value,
      language,
      submission_id: submissionId
    };

    if (testButton) disableButton(testButton);
    makeRequest(params);
  }

  function showTerminal(currentLanguage) {
    button.classList.remove('d-none');
    if (testButton) {
      if (testableLanguages.includes(currentLanguage)) {
        testButton.classList.remove('d-none');
      } else {
        testButton.classList.add('d-none');
      }
    }
    consoleWrapper.classList.remove('d-none');
  }

  function hideTerminal() {
    button.classList.add('d-none');
    if (testButton) testButton.classList.add('d-none');
    consoleWrapper.classList.add('d-none');
  }

  function toggleTerminal(currentLanguage) {
    if (supportedLanguages.includes(currentLanguage)) {
      showTerminal(currentLanguage);
    } else {
      hideTerminal();
    }
  }

  function handleLanguageChange(e) {
    language = e.detail.language;
    toggleTerminal(e.detail.language);
  }

  toggleTerminal(language);
  button.addEventListener('click', run);
  if (testButton) testButton.addEventListener('click', grade);
  this.addEventListener('ace-editor:change-language', handleLanguageChange);
});
