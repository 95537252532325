/* global I18n */

import onmount from 'onmount';
import snackbar from '@components/snackbar';
import Rails from '@rails/ujs';
import { getPresignedUrl, uploadToObjectStore, createUpload } from '@modules/upload-files';
import { MDCLinearProgress } from '@material/linear-progress';
import { showPreview, hidePreview } from '@modules/preview-media';

onmount('[data-js-upload-file]', function () {
  const self = this;

  const sizeLimit = this.dataset.sizeLimit || 25;
  const maxFileSize = sizeLimit * 1000 * 1000;
  const maxFileSizeText = `${sizeLimit} MB`;

  const { url, uploadUrl, extensionBlacklist } = this.dataset;
  const params = JSON.parse(this.dataset.params || null);
  const progressEl = this.querySelector('.mdc-linear-progress') ||
    this.parentElement.querySelector('.mdc-linear-progress');
  if (progressEl && !progressEl.MDCLinearProgress) {
    progressEl.MDCLinearProgress = new MDCLinearProgress(progressEl);
    progressEl.setAttribute('data-mdc-auto-init-state', 'initialized');
  }
  const input = this.querySelector('input[type="file"]');
  const previewWrapper = this.parentElement.querySelector('[data-js-preview-wrapper]');
  const removeUpload = this.querySelector('[data-js-remove-upload]');
  const eventTarget = document.querySelector(this.dataset.eventTarget);
  const instruction = this.parentElement.querySelector('[data-js-upload-instruction]');
  const status = this.parentElement.querySelector('[data-js-upload-status]');
  function triggerLoading() {
    if (!eventTarget) return;

    const uploadingEvent = new CustomEvent('file:loading');
    eventTarget.dispatchEvent(uploadingEvent);
  }

  function triggerSuccess(state) {
    if (!eventTarget) return;

    const details = {};
    if (params.submission_id) details[state] = [params.submission_id];
    const savedEvent = new CustomEvent('file:success', {
      detail: details
    });
    eventTarget.dispatchEvent(savedEvent);
  }

  function showProgress() {
    progressEl.classList.remove('d-none');
  }

  function hideProgress() {
    progressEl.classList.add('d-none');
  }

  function resetProgress() {
    progressEl.MDCLinearProgress.foundation.setProgress(0);
    showProgress();
  }

  function updateProgress(event) {
    const progress = event.loaded / event.total;
    progressEl.MDCLinearProgress.foundation.setProgress(progress);
    if (progress > 0.99) hideProgress();
  }

  function setFilePath(files) {
    const fileName = (files.length > 1 ? I18n.t('js.uploads.files_selected', { count: files.length }) : files[0].name);
    self.querySelector('[data-js-file-name]').innerHTML = fileName;
  }

  function clearFilePath() {
    self.querySelector('[data-js-file-name]').innerHTML = '';
  }

  function showFileRemove(response) {
    removeUpload.setAttribute('data-upload-id', response.id);
    removeUpload.classList.remove('d-none');
  }

  function hideFileRemove() {
    removeUpload.classList.add('d-none');
  }

  function showInstructionAndHideStatus() {
    if (instruction) instruction.classList.remove('d-none');
    if (status) status.classList.add('d-none');
  }

  function hideInstructionAndShowStatus() {
    if (instruction) instruction.classList.add('d-none');
    if (status) status.classList.remove('d-none');
  }

  function appendDownloadLink(response) {
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', `/uploads/${response.id}/download`);
    downloadLink.setAttribute('target', '_blank');
    downloadLink.textContent = response.file_name;
    self.querySelector('[data-js-file-name]').appendChild(downloadLink);
  }

  function sanitizeFile(file) {
    return new File([file], file.name.normalize('NFKD').replace(/\p{Diacritic}/gu, ''), { type: file.type });
  }

  function handleFileReupload() {
    this.value = null;
  }

  function handleFileUpload(e) {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return true;

    const fileExtension = `.${selectedFile.name.split('.').pop()}`;

    if (extensionBlacklist && JSON.parse(extensionBlacklist).includes(fileExtension)) {
      return snackbar(I18n.t('js.uploads.unsupported_file_type', {
        file_extension: fileExtension
      }), true);
    }

    if (selectedFile.size > maxFileSize) {
      return snackbar(I18n.t('js.uploads.file_too_large', {
        filesize: maxFileSizeText
      }), true);
    }

    if (url === undefined || url === '') {
      setFilePath(e.target.files);
      return false;
    }

    const file = sanitizeFile(selectedFile);
    triggerLoading();
    clearFilePath();
    return getPresignedUrl(url, file.name)
      .then((data) => uploadToObjectStore(file, data.put_url, self, updateProgress, resetProgress)
        .then(() => createUpload(file, data, params, uploadUrl)
          .then((response) => {
            triggerSuccess('answered');

            if (removeUpload) showFileRemove(response);
            showPreview(previewWrapper, file, response);
            appendDownloadLink(response);
            hideInstructionAndShowStatus();
          })))
      .catch((err) => {
        progressEl.classList.add('d-none');
        let message;
        if (err.message) {
          message = Object.entries(err.message).map(([key, value]) => `${key} ${value}`).join('');
        }
        snackbar(message || I18n.t('js.uploads.failed'), true);
        clearFilePath();
      });
  }

  function handleFileRemove() {
    const { uploadId } = this.dataset;

    triggerLoading();
    Rails.ajax({
      url: `/uploads/${uploadId}`,
      type: 'DELETE',
      dataType: 'json',
      success: () => {
        triggerSuccess('unanswered');

        if (removeUpload) hideFileRemove();
        clearFilePath();
        hidePreview(previewWrapper);
        showInstructionAndHideStatus();
      }
    });
  }

  input.addEventListener('change', handleFileUpload);
  input.addEventListener('click', handleFileReupload);
  if (removeUpload) removeUpload.addEventListener('click', handleFileRemove);
});
