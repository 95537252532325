{
  "nl": {
    "js": {
      "redactor": {
        "accessibility-help-label": "RTF editor",
        "add-gap": "Voeg invulveld toe",
        "add-head": "Voeg titel toe",
        "add-variable": "Voeg variabele toe",
        "align": "Uitlijnen",
        "align-center": "Midden uitlijnen",
        "align-left": "Links uitlijnen",
        "align-right": "Rechts uitlijnen",
        "allow-microphone-access": "Geef alsjeblieft toegang tot je microfoon",
        "audio": "Audio-opname",
        "background": "Achtergrond",
        "bold": "Vet",
        "bold-abbr": "B",
        "bulletslist": "Lijst met opsommingstekens",
        "cancel": "Annuleren",
        "caption": "Onderschrift",
        "center": "Centreer",
        "change": "Verander",
        "change-picture": "Verander foto",
        "chars": "Karakters",
        "choose": "Kies",
        "close": "Afsluiten",
        "code": "Code",
        "delete": "Verwijder",
        "delete-column": "Verwijder kolom",
        "delete-head": "Verwijder titel",
        "delete-row": "Verwijder rij",
        "delete-table": "Verwijder tabel",
        "deleted": "Doorstreept",
        "deleted-abbr": "S",
        "drawing": "Tekening",
        "edit": "Bewerken",
        "equation": "Vergelijking",
        "error-accessing-microphone": "Er was een probleem met je microfoon benaderen",
        "file": "Bestand",
        "filename": "Bestandsnaam",
        "fontcolor": "Tekstkleur",
        "format": "Koptekst",
        "fullscreen": "Volledig scherm",
        "gap": "Invulveld",
        "grade-formula-points": "Behaalde punten",
        "grade-formula-total": "Totale punten",
        "heading1": "Koptekst 1",
        "heading2": "Koptekst 2",
        "heading3": "Koptekst 3",
        "heading4": "Koptekst 4",
        "heading5": "Koptekst 5",
        "heading6": "Koptekst 6",
        "horizontalrule": "Streep",
        "image": "Afbeelding",
        "image-position": "Positie",
        "indent": "Inspringen",
        "insert": "Invoegen",
        "insert-column-left": "Kolom links invoegen",
        "insert-column-right": "Kolom rechts invoegen",
        "insert-row-above": "Rij boven invoegen",
        "insert-row-below": "Rij onder invoegen",
        "insert-table": "Tabel invoegen",
        "italic": "Cursief",
        "italic-abbr": "I",
        "left": "Links",
        "link": "Link",
        "link-edit": "Link Bewerken",
        "link-in-new-tab": "Open link in nieuw tabblad",
        "link-insert": "Link invoegen",
        "lists": "Lijsten",
        "make-recording-warning": "Maak eerst een opname",
        "maxWords": "Maximaal aantal woorden",
        "no-variables": "Geen variabelen gedefinieerd",
        "none": "Geen",
        "numberslist": "Genummerde lijst",
        "optionList": "Antwoordenlijst",
        "optional": "Optioneel",
        "orderedlist": "Geordende lijst",
        "outdent": "Uitspringen",
        "paragraph": "Normale tekst",
        "pause": "Pauze",
        "quote": "Citaat",
        "record": "Opnemen",
        "redo": "Doe opnieuw",
        "remove": "Verwijder",
        "resume": "Hervatten",
        "right": "Rechts",
        "save": "Opslaan",
        "save as shortcut": "Opslaan als quick comment",
        "shortcuts": "Quick comments",
        "show shortcuts": "Laat quick comments zien",
        "specialchars": "Speciale tekens",
        "stop": "Stop",
        "stream-unavailable": "Video stream niet beschikbaar.",
        "subscript": "Subscript",
        "subscript-abbr": "Sub",
        "superscript": "Superscript",
        "superscript-abbr": "Sup",
        "table": "Tabel",
        "take-picture": "Maak foto",
        "take-picture-warning": "Maak eerst een foto",
        "text": "Tekst",
        "title": "Alternatieve tekst",
        "twemoji": "Emoji",
        "underline": "Onderstreept",
        "underline-abbr": "U",
        "undo": "Maak ongedaan",
        "unlink": "Ontkoppelen",
        "unorderedlist": "Ongeordende lijst",
        "upload": "Upload",
        "upload-change-label": "Sleep een nieuwe afbeelding om aan te passen",
        "upload-label": "Sleep bestanden of klik om te uploaden",
        "variable": "Variabele",
        "webcam": "Webcamfoto",
        "words": "Woorden"
      }
    }
  }
}