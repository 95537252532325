import onmount from 'onmount';
import Rails from '@rails/ujs';

onmount('[data-js-comment-panel]', function () {
  const iframe = document.querySelector('#pdf-iframe');
  const annotationCommentPanel = this.querySelector('[data-js-annotation-comment-panel]');

  function showEmptyState() {
    annotationCommentPanel.innerHTML = '';
    document.querySelector('[data-js-annotation-comment-panel-empty-state]').classList.remove('d-none');
  }

  function hideEmptyState() {
    document.querySelector('[data-js-annotation-comment-panel-empty-state]').classList.add('d-none');
  }

  function removeAnnotationWithoutComments(annotationId) {
    const documentId = document.querySelector('[data-js-file-tab].mdc-tab--active').dataset.uploadId;
    const pdfWindow = iframe.contentWindow;
    const pdfDocument = iframe.contentDocument;

    const annotation = pdfDocument.querySelector("[data-pdf-annotate-id='#{annotationId}']");
    if (!annotation || annotation.getAttribute('comment-count') > 0) return;

    annotation.remove();
    pdfWindow.PDFAnnotate.UI.destroyEditOverlay();
    pdfWindow.PDFAnnotate.getStoreAdapter().deleteAnnotation(documentId, annotationId);
  }

  function pageHasComments(page) {
    const pdfDocument = iframe.contentDocument;
    return pdfDocument.querySelectorAll(`[data-page-number='${page}'] [data-pdf-annotate-type='point']`).length > 0;
  }

  function getCommentList(documentId = null, page = null) {
    const pdfWindow = iframe.contentWindow;
    if (!documentId) {
      documentId = document.querySelector('[data-js-file-tab].mdc-tab--active').dataset.uploadId; // eslint-disable-line
    }
    if (!page) {
      page = pdfWindow.PDFViewerApplication.pdfViewer.currentPageNumber; // eslint-disable-line
    }

    if (pageHasComments(page)) {
      Rails.ajax({
        url: `/annotations/comment_list.js?upload_id=${documentId}&page=${page}`,
        type: 'GET'
      });
      hideEmptyState();
    } else {
      showEmptyState();
    }
  }

  function handleAnnotationBlur(e) {
    let annotationId;
    let checkAnnotationWithoutComments = false;

    if (e.detail) ({ annotationId, checkAnnotationWithoutComments } = e.detail);

    if (!annotationId) {
      const pdfDocument = iframe.contentDocument;
      const overlay = pdfDocument.getElementById('pdf-annotate-edit-overlay');
      if (overlay) {
        annotationId = overlay.getAttribute('data-target-id'); // eslint-disable-line
      }
    }

    if (checkAnnotationWithoutComments) {
      removeAnnotationWithoutComments(annotationId);
    }

    const pdfWindow = iframe.contentWindow;
    pdfWindow.PDFAnnotate.UI.destroyEditOverlay();

    if (document.querySelector(`[data-annotation-id='${annotationId}']`)) {
      getCommentList();
    }
  }
  this.handleAnnotationBlur = handleAnnotationBlur;

  function handleAnnotationClick(e) {
    const { annotationId } = e.detail;
    const pdfWindow = iframe.contentWindow;
    const pdfDocument = iframe.contentDocument;
    const target = pdfDocument.querySelector(`[data-pdf-annotate-id='${annotationId}']`);

    pdfWindow.emitter.emit('annotation:click', target);
  }
  this.handleAnnotationClick = handleAnnotationClick;

  function handleAnnotationComments() {
    getCommentList();
  }
  this.handleAnnotationComments = handleAnnotationComments;

  document.addEventListener('annotation:blur', this.handleAnnotationBlur);
  document.addEventListener('annotation:click', this.handleAnnotationClick);
  document.addEventListener('annotation:comments', this.handleAnnotationComments);
}, function () {
  document.removeEventListener('annotation:blur', this.handleAnnotationBlur);
  document.removeEventListener('annotation:click', this.handleAnnotationClick);
  document.removeEventListener('annotation:comments', this.handleAnnotationComments);
});
