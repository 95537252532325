/* global I18n */

import snackbar from '@components/snackbar';

function quizPanel() {
  return document.querySelector('[data-js-quiz-panel]');
}

function showSnackbar(self) {
  if (!self.mdcSnackbar || !self.mdcSnackbar.isOpen) {
    self.mdcSnackbar = snackbar(I18n.t('js.quizzes.saving_in_progress'), true);
  }
}

function waitForRequests() {
  showSnackbar(this);

  if (quizPanel().requestCount === 0) {
    if (this.mdcSnackbar) this.mdcSnackbar.close();
    this.click();
  } else {
    setTimeout(waitForRequests.bind(this), 250);
  }
}

function waitForUnsavedForms() {
  showSnackbar(this);

  if (!quizPanel().querySelector('form[data-saved="false"]')) {
    if (this.mdcSnackbar) this.mdcSnackbar.close();
    this.click();
  } else {
    const saveEvent = new CustomEvent('quiz:save');
    quizPanel().dispatchEvent(saveEvent);

    setTimeout(waitForUnsavedForms.bind(this), 250);
  }
}

export function activeRequests() {
  if (quizPanel().requestCount > 0) {
    setTimeout(waitForRequests.bind(this), 250);
    return true;
  }

  if (quizPanel().querySelector('form[data-saved="false"]')) {
    setTimeout(waitForUnsavedForms.bind(this), 250);
    return true;
  }
  return false;
}
