/* global Redactor */

import onmount from 'onmount';

(function ($R) {
  $R.add('plugin', 'audio', {
    translations: {
      en: {
        'allow-microphone-access': 'Please allow access to your microphone',
        audio: 'Audio recording',
        cancel: 'Cancel',
        'error-accessing-microphone': 'There was an error accessing you microphone.',
        'make-recording-warning': 'Please make a recording first',
        pause: 'Pause',
        record: 'Record',
        resume: 'Resume',
        save: 'Save',
        stop: 'Stop'
      }
    },
    modals: {
      audio:
        "<div class='audio-recording'>" +
          '<div>' +
            "<div id='time' class='time'></div>" +
            "<div id='max-time' class='time'>02:30</div>" +
            "<canvas id='visualizer' width='704px' height='120px'></canvas>" +
            "<div class='controls'>" +
              "<button id='start' style='float:none;' class='start'>" +
                "<i class='material-icons'>play_arrow</i>## record ##" +
              '</button>' +
              "<button id='pause' style='float:none;' class='pause'>" +
                "<i class='material-icons'>pause</i>## pause ##" +
              '</button>' +
              "<button id='resume' style='float:none;' class='resume'>" +
                "<i class='material-icons'>play_arrow</i>## resume ##" +
              '</button>' +
              "<button id='stop' style='float:none;' class='stop'>" +
                "<i class='material-icons'>stop</i>## stop ##" +
              '</button>' +
            '</div>' +
          '</div>' +
          "<div id='warning'>## make-recording-warning ##</div>" +
        '</div>'
    },
    init(app) {
      this.app = app;
      this.lang = app.lang;
      this.opts = app.opts;
      this.toolbar = app.toolbar;
      this.component = app.component;
      this.insertion = app.insertion;
      this.inspector = app.inspector;

      // locals
      this.animationFrameId = null;
      this.intervalArray = [];
      this.seconds = 0;
    },

    // messages
    onmodal: {
      audio: {
        open($modal) {
          this.buildModal($modal);
        },
        opened() {
          if (this.errorLoadingStream) {
            this.app.api('module.modal.close');
          }
        },
        close() {
          this.stopAudio();
          this.stopTimer();
          this.stopVisualize();
        },
        insert() {
          this.insert();
        }
      }
    },

    onupload: {
      audio: {
        complete(response) {
          this.buildComponent(response);
        }
      }
    },

    oncontextbar(e, contextbar) {
      const data = this.inspector.parse(e.target);
      if (data.isComponentType('audio')) {
        const node = data.getComponent();
        const buttons = {
          remove: {
            title: this.lang.get('delete'),
            api: 'plugin.audio.remove',
            args: node
          }
        };

        contextbar.set(e, node, buttons, 'bottom');
      }
    },

    // public
    start() {
      const self = this;
      this.detectAudio((hasMicrofoon) => {
        if (!hasMicrofoon) { return; }

        const obj = {
          title: self.lang.get('audio'),
          api: 'plugin.audio.open'
        };

        const $button = self.toolbar.addButton('audio', obj);
        $button.setIcon("<i class='material-icons'>mic</i>");
        if (self.app.isReadOnly()) $button.disable();
      });
    },
    open() {
      const options = {
        title: this.lang.get('audio'),
        width: '800px',
        name: 'audio',
        handle: 'insert',
        commands: {
          insert: { title: this.lang.get('save') },
          cancel: { title: this.lang.get('cancel') }
        }
      };

      this.app.api('module.modal.build', options);
    },
    insert() {
      if (this.audioRecorder.state === 'paused' || this.audioRecorder.state === 'recording') {
        this.handleStop();
        this.insertAfterStop = true;
        return;
      }

      if (!this.audioBlob) {
        this.warning.style.display = 'inline-block';
        return;
      }

      const file = new File([this.audioBlob], 'recording', { type: this.fileType });
      const options = {
        url: this.opts.fileUpload,
        event: false,
        files: [file],
        name: 'audio'
      };
      this.app.api('module.upload.send', options);
      this.app.api('module.modal.close');
    },
    remove(node) {
      this.component.remove(node);
    },

    // private
    detectAudio(callback) {
      const md = navigator.mediaDevices;
      if (!md || !md.enumerateDevices || !window.MediaRecorder) {
        return callback(false);
      }

      return md.enumerateDevices().then((devices) => {
        callback(devices.some((device) => device.kind === 'audioinput'));
      });
    },
    buildModal($modal) {
      this.width = 640;
      this.height = 0;

      this.errorLoadingStream = false;
      this.stream = null;
      this.streaming = false;

      this.audioBlob = null;
      this.chunks = [];

      const $body = $modal.getBody();
      this.time = $body.find('#time').get();
      this.start = $body.find('#start').get();
      this.pause = $body.find('#pause').get();
      this.resume = $body.find('#resume').get();
      this.stop = $body.find('#stop').get();
      this.canvas = $body.find('#visualizer').get();
      this.warning = $body.find('#warning').get();

      this.addDraggableAttributes($body.nodes[0].parentElement);
      this.startAudio();
      this.addAudioEventListeners();
    },
    buildComponent(response) {
      const item = response['file-0'];
      const $wrapper = this.component.create('audio');
      const $audio = $R.dom('<audio>');

      $audio.attr('src', item.url);
      $audio.attr('controls', true);
      $audio.attr('controlsList', 'nodownload');

      $wrapper.append($audio);
      this.insertion.insertHtml($wrapper);
    },
    startAudio() {
      const self = this;
      navigator.mediaDevices.getUserMedia({ video: false, audio: true })
        .then((stream) => {
          self.stream = stream;
          self.audioRecorder = new MediaRecorder(stream);
          self.audioRecorder.addEventListener('stop', self.handleRecordingStop.bind(self));
          self.audioRecorder.addEventListener('dataavailable', self.handleRecordingAvailable.bind(self));
          self.start.style.display = 'inline-block';
          self.initVisualize();
        })
        .catch((err) => {
          self.errorLoadingStream = true;
          console.log(err); // eslint-disable-line no-console
          if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
            alert(self.lang.get('allow-access')); // eslint-disable-line no-alert
          } else {
            alert(self.lang.get('error-accessing-microphone')); // eslint-disable-line no-alert
          }
        });
    },
    addAudioEventListeners() {
      this.start.addEventListener('click', this.handleStart.bind(this));
      this.pause.addEventListener('click', this.handlePause.bind(this));
      this.resume.addEventListener('click', this.handleResume.bind(this));
      this.stop.addEventListener('click', this.handleStop.bind(this));
    },
    handleStart() {
      this.audioRecorder.start();

      this.startVisualize();
      this.startTimer();

      this.stop.style.display = 'inline-block';
      this.start.style.display = 'none';
      this.pause.style.display = 'inline-block';
      this.warning.style.display = 'none';
    },
    handlePause() {
      this.audioRecorder.pause();

      this.pauseVisualize();
      this.pauseTimer();

      this.stop.style.display = 'none';
      this.pause.style.display = 'none';
      this.resume.style.display = 'inline-block';
    },
    handleResume() {
      this.audioRecorder.resume();

      this.resumeVisualize();
      this.startTimer();

      this.stop.style.display = 'inline-block';
      this.resume.style.display = 'none';
      this.pause.style.display = 'inline-block';
      this.warning.style.display = 'none';
    },
    handleStop() {
      this.audioRecorder.stop();

      this.stopTimer();
      this.stopVisualize();

      this.stop.style.display = 'none';
      this.pause.style.display = 'none';
      this.start.style.display = 'none';
    },
    handleRecordingStop() {
      this.audioBlob = new Blob(this.chunks, { type: this.fileType || 'audio/ogg; codecs=opus' });
      this.chunks = [];

      if (this.insertAfterStop) {
        this.insert();
      }
    },
    handleRecordingAvailable(e) {
      this.chunks.push(e.data);
      if (e.data.type) {
        this.fileType = e.data.type;
      }
    },
    stopAudio() {
      if (!this.stream) return;

      this.stream.getTracks().forEach((track) => {
        track.stop();
      });
    },
    startTimer() {
      const self = this;
      const id = setInterval(() => {
        self.seconds += 1;
        self.updateTime();
      }, 1000);
      this.intervalArray.push(id);
    },
    pauseTimer() {
      let i = 0;
      while (i < this.intervalArray.length) {
        clearTimeout(this.intervalArray[i]);
        i += 1;
      }
    },
    stopTimer() {
      this.pauseTimer();
      this.seconds = 0;
    },
    updateTime() {
      if (this.seconds > 0) {
        const date = new Date(1970, 0, 1);
        date.setSeconds(this.seconds);
        this.time.innerHTML = date.toTimeString().replace(/.*(\d{2}:\d{2}).*/, '$1');
      } else {
        this.time.innerHTML = '';
      }

      if (this.seconds >= 150) this.handleStop();
    },
    initVisualize() {
      const AudioContext = window.AudioContext || window.webkitAudioContext || false;

      if (!AudioContext) {
        alert('Sorry, but the Web Audio API is not supported. Please use Google Chrome or Mozilla Firefox'); // eslint-disable-line no-alert
      }

      if (!this.audioCtx) {
        this.audioCtx = new AudioContext();
      }

      const source = this.audioCtx.createMediaStreamSource(this.stream);
      this.analyser = this.audioCtx.createAnalyser();
      this.analyser.fftSize = 32;
      const bufferLength = this.analyser.frequencyBinCount;

      this.dataArray = new Uint8Array(bufferLength);
      this.canvasCtx = this.canvas.getContext('2d');
      this.canvasHeight = this.canvas.height;
      this.canvasWidth = this.canvas.width;
      this.baseLine = (this.canvasHeight / 3) * 2;

      source.connect(this.analyser);

      // get DPI
      const dpi = window.devicePixelRatio;
      this.canvas.setAttribute('height', 120 * dpi);
      this.canvas.setAttribute('width', 704 * dpi);
      this.canvasCtx.scale(dpi, dpi);

      this.x = 0;
    },
    startVisualize() {
      const self = this;
      let maxFrequency = 0;

      // Time
      let now;
      let elapsed;
      const fps = 1;
      const fpsInterval = 1000 / fps;
      let then = Date.now();

      const barWidth = (this.canvasWidth * 1.0) / (2 * 2.5 * 60);

      function draw() {
        self.animationFrameId = requestAnimationFrame(draw);

        self.analyser.getByteTimeDomainData(self.dataArray);

        const max = Math.max.apply(null, self.dataArray);
        maxFrequency = Math.max(max, maxFrequency);

        now = Date.now();
        elapsed = now - then;

        if (elapsed > fpsInterval) {
          then = now - (elapsed % fpsInterval);

          const y = -(maxFrequency / 128.0 - 1) * self.canvasHeight;

          self.canvasCtx.translate(0.5, 0.5);
          self.canvasCtx.fillStyle = '#007dff';
          self.canvasCtx.fillRect(self.x, self.baseLine - 1, barWidth, y);
          self.canvasCtx.fillStyle = 'rgb(150, 188, 246)';
          self.canvasCtx.fillRect(self.x, self.baseLine + 1, barWidth, -y / 3);
          self.canvasCtx.translate(-0.5, -0.5);

          self.x += 2 * barWidth;

          maxFrequency = 0;
        }
      }

      draw();
    },
    pauseVisualize() {
      if (this.animationFrameId) {
        cancelAnimationFrame(this.animationFrameId);
        this.animationFrameId = null;
      }
    },
    resumeVisualize() {
      this.startVisualize();
    },
    stopVisualize() {
      this.pauseVisualize();
    },
    addDraggableAttributes(element) {
      element.setAttribute('data-js-draggable-dialog', '');
      element.setAttribute('data-js-dialog-container', '');
      element.querySelector('.redactor-modal-header').setAttribute('data-js-draggable-header', '');
      onmount();
    }
  });
}(Redactor));
(function ($R) {
  $R.add('class', 'audio.component', {
    mixins: ['dom', 'component'],
    init(app, el) {
      this.app = app;

      // init
      return (el && el.cmnt !== undefined) ? el : this.initialize(el);
    },

    // public
    getData() {
      return {
        type: this.getType()
      };
    },

    // private
    initialize(el) {
      const element = el || '<div>';

      this.parse(element);
      this.initWrapper();
    },
    getType() {
      return this.text().trim().replace(/[\u200B-\u200D\uFEFF]/g, '');
    },
    initWrapper() {
      this.addClass('redactor-component');
      this.attr({
        'data-redactor-type': 'audio',
        tabindex: '-1',
        contenteditable: false
      });
    }
  });
}(Redactor));
