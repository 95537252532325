import onmount from 'onmount';

onmount('[data-js-seb-form]', function () {
  const permittedProcess = this.querySelector('[data-js-permitted-processes]');
  const prohibitedProcess = this.querySelector('[data-js-prohibited-processes]');
  const addPermittedProcess = this.querySelector('[data-js-add-permitted-process]');
  const addProhibitedProcess = this.querySelector('[data-js-add-prohibited-process]');
  const addUrlFilterRules = this.querySelector('[data-js-add-url-filter-rule]');
  const urlFilterRules = this.querySelector('[data-js-url-filter-rules');
  const addBrowserExamKey = this.querySelector('[data-js-add-browser-exam-key]');
  const browserExamKeys = this.querySelector('[data-js-browser-exam-keys');

  function handleProcessField(event, process) {
    const closestProcess = event.target.closest(`[data-js-${process}-process]`);
    const osSelector = closestProcess.querySelector(`[data-js-${process}-os-selector]`);
    const identifierField = closestProcess.querySelector(`[data-js-${process}-identifier-field]`);
    const executableField = closestProcess.querySelector(`[data-js-${process}-executable-field]`);
    if (osSelector.querySelector('[data-js-collection] .mdc-deprecated-list-item--selected').dataset.value === '1') {
      identifierField.placeholder = 'Executable name';
      executableField.placeholder = 'Executable.exe';
    } else {
      identifierField.placeholder = 'com.example.example';
      executableField.placeholder = 'Exact exacutable name';
    }
  }

  if (addPermittedProcess) {
    addPermittedProcess.addEventListener('click', () => {
      permittedProcess.closest('div').classList.remove('d-none');
    });
  }

  if (addProhibitedProcess) {
    addProhibitedProcess.addEventListener('click', () => {
      prohibitedProcess.closest('div').classList.remove('d-none');
    });
  }

  if (addUrlFilterRules) {
    addUrlFilterRules.addEventListener('click', () => {
      urlFilterRules.closest('div').classList.remove('d-none');
    });
  }

  if (addBrowserExamKey) {
    addBrowserExamKey.addEventListener('click', () => {
      browserExamKeys.closest('div').classList.remove('d-none');
    });
  }

  if (prohibitedProcess) {
    prohibitedProcess.addEventListener('change', (e) => {
      handleProcessField(e, 'prohibited');
    });
  }

  if (permittedProcess) {
    permittedProcess.addEventListener('change', (e) => {
      handleProcessField(e, 'permitted');
    });
  }
});
