import I18n from 'i18n-js';

const i18nEl = document.querySelector('[data-js-i18n]');
const { translations, defaultLocale, locale } = i18nEl.dataset;

I18n.translations = JSON.parse(translations);
I18n.defaultLocale = defaultLocale;
I18n.locale = locale;
I18n.locales.nl_vo = ['nl_vo', 'nl', 'en'];
I18n.locales.nl = ['nl', 'en'];
I18n.locales.de = ['de', 'en'];
I18n.locales.fr = ['fr', 'en'];
I18n.locales.lv = ['lv', 'en'];
I18n.locales.es = ['es', 'en'];
I18n.locales.pt = ['pt', 'en'];
I18n.locales.it = ['it', 'en'];
I18n.locales.pl = ['pl', 'en'];

window.I18n = I18n;
export default I18n;
