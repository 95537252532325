import { Controller } from '@hotwired/stimulus';
import { install, uninstall } from '@github/hotkey';
import { useMutation } from 'stimulus-use';

export default class extends Controller {
  connect() {
    useMutation(this, { element: document.body, attributeFilter: ['data-hotkeys-disabled'], attributeOldValue: true });
    install(this.element);
  }

  disconnect() {
    uninstall(this.element);
  }

  mutate(entries) {
    if (entries[0].oldValue) {
      install(this.element);
    } else {
      uninstall(this.element);
    }
  }
}
