import onmount from 'onmount';

onmount('[data-js-annotation-comment-panel]', function () {
  function handleCommentPanelClick(e) {
    if (e.target.closest('[data-js-back-to-overview]')) {
      const annotationBlurEvent = new CustomEvent('annotation:blur');
      document.dispatchEvent(annotationBlurEvent);
    }
  }

  this.addEventListener('click', handleCommentPanelClick);
});
