import onmount from 'onmount';
import Rails from '@rails/ujs';
import { MDCDataTable } from '@material/data-table';

onmount('[data-js-table-action]', function () {
  const self = this;
  const dialog = this.closest('[data-mdc-dialog]');
  const url = new URL(window.location.origin + this.dataset.url);
  const type = this.dataset.method || 'GET';
  const requiredSelectedCount = this.getAttribute('data-js-selected-rows');
  const removeSelectionBeforeSend = this.hasAttribute('data-js-remove-selection-before-send');
  const confirmMessage = this.getAttribute('data-confirm');
  let dataTableEl;

  if (dialog) {
    dataTableEl = dialog.querySelector('.mdc-data-table');
  } else {
    dataTableEl = document.querySelector('.mdc-data-table');
  }

  if (!dataTableEl.MDCDataTable) {
    dataTableEl.MDCDataTable = new MDCDataTable(dataTableEl);
    dataTableEl.setAttribute('data-mdc-auto-init-state', 'initialized');
  }

  function getSelectedIds() {
    if (dataTableEl.querySelector('[data-js-table-select-all][data-enabled="true"]')) return 'all';

    return dataTableEl.MDCDataTable.getSelectedRowIds();
  }

  function handleClick() {
    if (type === 'NONE') return;

    const selectedRowIds = getSelectedIds();

    url.searchParams.set('row_ids', selectedRowIds);

    if (self.hasAttribute('data-confirm') && !Rails.confirm(confirmMessage, self)) return;
    if (self.hasAttribute('data-disable-with')) Rails.disableElement(self);

    Rails.ajax({
      url: url.toString(),
      type,
      dataType: 'script',
      beforeSend: () => {
        if (removeSelectionBeforeSend) {
          dataTableEl.MDCDataTable.setSelectedRowIds([]);
          dataTableEl.MDCDataTable.emit('MDCDataTable:rowSelectionChanged');
        }
        return true;
      },
      complete: (xhr) => {
        Rails.fire(document.body, 'ajax:complete', [xhr]);
        Rails.enableElement(self);
      }
    });
  }

  function handleRowSelection() {
    const selectedCount = dataTableEl.MDCDataTable.foundation.adapter.getSelectedRowCount();

    if (selectedCount && (!requiredSelectedCount || requiredSelectedCount.toString() === selectedCount.toString())) {
      if (self.tagName === 'BUTTON') self.removeAttribute('disabled');
      if (self.tagName === 'LI') self.classList.remove('mdc-deprecated-list-item--disabled');
    } else {
      if (self.tagName === 'BUTTON') self.setAttribute('disabled', true);
      if (self.tagName === 'LI') self.classList.add('mdc-deprecated-list-item--disabled');
    }
  }

  this.addEventListener('click', handleClick);

  dataTableEl.MDCDataTable.listen('MDCDataTable:rowSelectionChanged', handleRowSelection);
  dataTableEl.MDCDataTable.listen('MDCDataTable:selectedAll', handleRowSelection);
  dataTableEl.MDCDataTable.listen('MDCDataTable:unselectedAll', handleRowSelection);
});
