/* global I18n */

import { renderKatex } from '@vendor/katex';

export function renderCompiledResponse() {
  const responseArea = this.closest('[data-js-response]');
  const responseInput = responseArea.querySelector('[data-js-response-input]');
  const compiledResponse = responseArea.querySelector('[data-js-compiled-response]');
  if (compiledResponse) compiledResponse.remove();

  let content;

  if (this.hasAttribute('data-js-redactor')) {
    content = this.value;
  } else {
    content = responseArea.querySelector('.equation-responses').innerHtml;
  }

  const template = `
  <div class='compiled-response overflow-auto' data-js-compiled-response tabindex='0'>
    <div class='redactor-content' data-js-katex data-js-redactor-content>${content}</div>
    <div class='hover-effect'>${I18n.t('js.quizzes.click_to_edit')}</div>
  </div>
  `;
  responseArea.insertAdjacentHTML('beforeend', template);

  responseInput.classList.add('d-none');
  renderKatex(responseArea);
}
