/* global I18n, google */

import onmount from 'onmount';
import Rails from '@rails/ujs';
import { debounce, serialize } from '@modules/custom';

onmount('[data-js-results-forecast]', function () {
  const chartWrapper = this.querySelector('[data-js-chart]');
  const startDate = this.querySelector('[data-js-start-date]');
  const endDate = this.querySelector('[data-js-end-date]');
  const { url } = this.dataset;

  function parseData(data) {
    data.forEach((row, i) => {
      const date = new Date(data[i][0]);
      data[i][0] = date;
      data[i][2] = { v: Math.max(data[i][2] - data[i][1], 0), f: data[i][2].toString() };
      data[i][1] = { v: data[i][1], f: data[i][1].toString() };
      data[i][4] = { v: Math.max(data[i][4] - data[i][3], 0), f: data[i][4].toString() };
      data[i][3] = { v: data[i][3], f: data[i][3].toString() };
    });

    return data;
  }

  function drawChart(data) {
    const dataTable = new google.visualization.DataTable();
    dataTable.addColumn('date', '');
    dataTable.addColumn('number', I18n.t('js.dashboard.results_forecast.exam_results'));
    dataTable.addColumn('number', I18n.t('js.dashboard.results_forecast.expected_exam_results'));
    dataTable.addColumn('number', I18n.t('js.dashboard.results_forecast.quiz_results'));
    dataTable.addColumn('number', I18n.t('js.dashboard.results_forecast.expected_quiz_results'));
    dataTable.addRows(parseData(data));

    const options = {
      isStacked: true,
      vAxis: {
        viewWindow: {
          min: 0,
          max: Math.max(
            dataTable.getColumnRange(1).max + dataTable.getColumnRange(2).max,
            dataTable.getColumnRange(3).max + dataTable.getColumnRange(4).max,
            100
          )
        }
      },
      hAxis: { format: 'dd/MM/yyyy' },
      series: {
        2: { targetAxisIndex: 1 },
        3: { targetAxisIndex: 1 }
      }
    };

    const chart = new (google.charts.Bar)(chartWrapper);
    chart.draw(dataTable, google.charts.Bar.convertOptions(options));
  }

  function getChartData() {
    const params = { start_date: startDate.value, end_date: endDate.value };

    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      data: serialize(params),
      success: (data) => drawChart(data.data)
    });
  }
  this.getChartData = debounce(getChartData);

  google.charts.load('current', { packages: ['bar'] });
  google.charts.setOnLoadCallback(getChartData);

  [startDate, endDate].forEach((el) => {
    el.addEventListener('change', getChartData);
  });

  window.addEventListener('resize', this.getChartData);
}, function () {
  window.removeEventListener('resize', this.getChartData);
});
