/* eslint-disable */
/* global I18n */

import onmount from 'onmount';
import { MDCChipSet } from '@material/chips/deprecated';

onmount('[data-js-feedback-form] .mdc-chip-set', function () {
  const form = this.closest('form');
  const typeInput = form.querySelector('[data-js-type-input]');
  const messageInput = form.querySelector('[data-js-message-input]');
  const formElements = form.querySelector('[data-js-form-elements]');
  const templates = {};

  if (!this.MDCChipSet) {
    this.MDCChipSet = new MDCChipSet(this);
    this.setAttribute('data-mdc-auto-init-state', 'initialized');
  }

  function handleChipSelect(e) {
    const { value, type } = e.target.dataset;

    if (!e.detail.selected) {
      templates[type] = messageInput.redactor.source.getCode();
      return;
    }

    typeInput.value = value;

    const text = templates[type] || I18n.t(`js.feedback.message_templates.${type}_html`, { defaultValue: '' });
    messageInput.redactor.source.setCode(text);
  }

  function toggleSelect(chipId) {
    if (this.selectedChipIds_.indexOf(chipId) >= 0) return;

    this.selectAndNotifyClients_(chipId);
  }
  this.MDCChipSet.foundation.toggleSelect_ = toggleSelect;

  this.MDCChipSet.listen('MDCChip:selection', handleChipSelect);
});
