/* global I18n */

import onmount from 'onmount';
import snackbar from '@components/snackbar';
import Rails from '@rails/ujs';
import { renderCompiledResponse } from '@modules/quiz/compiling';
import { displayUpdatingStatus } from '@modules/quiz/saving';
import { lockSubmission, unlockSubmission } from '@modules/quiz/locking';
import { updateActiveSubmission } from '@modules/quiz/navigating';

onmount('[data-js-response="text"]', function () {
  const self = this;
  const form = this.closest('form');
  const { submissionId } = this.closest('[data-js-quiz-subquestion]').dataset;
  const input = this.querySelector('[data-js-response-input]');
  const textarea = this.querySelector('textarea');
  let redactor = null;

  function handleFocus() {
    lockSubmission.call(textarea);

    updateActiveSubmission(submissionId);
  }

  function handleBlur() {
    unlockSubmission.call(textarea);

    if (form.getAttribute('data-saved') === 'false') {
      form.setAttribute('data-saved', true);
      displayUpdatingStatus(form);
      Rails.fire(form, 'submit');
    }

    renderCompiledResponse.call(textarea);
  }

  function handleContentChanged() {
    form.setAttribute('data-saved', false);
  }

  function addEventHandlers() {
    redactor.callback.add('focus', handleFocus);
    redactor.callback.add('blur', handleBlur);

    const contentChangedEvents = [
      'keyup', 'inserted', 'format', 'undo', 'redo',
      'image.changed', 'image.deleted', 'image.resized',
      'list.toggle', 'list.indent', 'list.outdent',
      'table.insert', 'table.addRowAbove', 'table.addRowBelow', 'table.addColumnLeft',
      'table.addColumnRight', 'table.addHead', 'table.deleteHead', 'table.deleteColumn',
      'table.deleteRow', 'table.deleteTable'
    ];
    contentChangedEvents.forEach((event) => {
      redactor.callback.add(event, handleContentChanged);
    });
  }

  function handleCompiledClick(e) {
    if (!e.target.matches('[data-js-compiled-response]') &&
        !e.target.parentElement.matches('[data-js-compiled-response]')) return;
    e.preventDefault();

    if (self.closest('[data-js-quiz-subquestion]').getAttribute('data-locked') === 'true') {
      snackbar(I18n.t('js.quizzes.locked_by_another'));
      return;
    }

    input.classList.remove('d-none');
    e.target.closest('[data-js-compiled-response]').remove();

    if (redactor) redactor.editor.endFocus();
  }

  function handleFormSubmit() {
    redactor.editor.app.broadcast('hardsync');
  }

  function waitForRedactor() {
    if (self.querySelector('.redactor-in')) {
      ({ redactor } = self.querySelector('[data-js-redactor]'));
      addEventHandlers();
      return;
    }
    setTimeout(waitForRedactor, 100);
  }

  function handleFocusedEnter(e) {
    if (e.keyCode !== 13 && e.keyCode !== 32) return;

    handleCompiledClick(e);
  }

  waitForRedactor();
  this.addEventListener('keydown', handleFocusedEnter);
  this.addEventListener('click', handleCompiledClick);
  form.addEventListener('submit', handleFormSubmit);
});
