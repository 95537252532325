import onmount from 'onmount';

onmount('[data-js-annotation-with-comments]', function () {
  const annotationId = this.dataset.jsAnnotationWithComments;

  function handleAnnotationClick() {
    const annotationClickEvent = new CustomEvent('annotation:click', {
      detail: {
        annotationId
      }
    });
    document.dispatchEvent(annotationClickEvent);
  }

  this.addEventListener('click', handleAnnotationClick);
});
