/* global I18n */

import onmount from 'onmount';
import snackbar from '@components/snackbar';
import Rails from '@rails/ujs';
import { serialize, throttle } from '@modules/custom';
import { MDCLinearProgress } from '@material/linear-progress';

onmount('[data-js-dropzone]', function () {
  const self = this;
  const { url } = this.dataset;
  const withTimestamp = this.dataset.jsWithTimestamp === 'true';
  const maxFilesize = this.dataset.jsSizeLimit || 2000;
  const extensionBlacklist = this.dataset.jsExtensionBlacklist;
  const acceptedFiles = this.dataset.jsAcceptedFiles;
  const previewsContainer = document.querySelector('[data-js-uploads]');
  const uploadButton = document.querySelector('[data-js-add-file]');
  const previewTemplate = previewsContainer.querySelector('[data-js-preview-template]');
  const params = JSON.parse(this.dataset.params);

  function dispatchUploadEvent(eventName, fileName = '') {
    const event = new CustomEvent(eventName, {
      detail: { file_name: fileName }
    });
    document.dispatchEvent(event);
  }

  function createKey(file, action, bucket) {
    const prefix = action.replace(`${bucket}/`, '');

    return prefix + file.upload.filename;
  }

  function renameFile(file) {
    let { name } = file;
    name = name.normalize('NFKD').replace(/\p{Diacritic}/gu, '');
    if (withTimestamp) name = `${new Date().toJSON()}/${name}`;

    return name;
  }

  function getTranslations() {
    return I18n.translations[I18n.locale].js.dropzone || {};
  }

  const checkSession = throttle(() => {
    Rails.ajax({
      url: '/heartbeat.json',
      type: 'GET',
      error: (data) => {
        if (data.status === 401) window.location.reload();
      }
    });
  }, 5 * 1000);

  async function getDropzone() {
    const module = await import(/* webpackChunkName: "dropzone" */ 'dropzone');
    return module.default;
  }

  getDropzone().then((Dropzone) => {
    Dropzone.autoDiscover = false;

    const options = {
      clickable: uploadButton || false,
      createImageThumbnails: false,
      maxFilesize,
      parallelUploads: 5,
      previewsContainer,
      previewTemplate: previewTemplate.innerHTML,
      acceptedFiles,
      renameFile,
      timeout: 60 * 60 * 1000,
      ...getTranslations()
    };

    self.dropzone = new Dropzone(self, options);

    self.dropzone.on('addedfile', (file) => {
      checkSession();
      const fileExtension = `.${file.name.split('.').pop()}`;

      const blankslate = previewsContainer.querySelector('.blankslate');
      if (blankslate) blankslate.remove();
      file.previewElement.setAttribute('data-uuid', file.upload.uuid);
      previewTemplate.after(file.previewElement);

      if (extensionBlacklist && JSON.parse(extensionBlacklist).includes(fileExtension)) {
        self.dropzone.cancelUpload(file);
        return snackbar(I18n.t('js.uploads.unsupported_file_type', { file_extension: fileExtension }), true);
      }

      return dispatchUploadEvent('dropzone:fileAdded');
    });

    self.dropzone.on('uploadprogress', (file, progress) => {
      const progressEl = file.previewElement.querySelector('[data-dz-uploadprogress]');

      if (!progressEl.MDCLinearProgress) {
        progressEl.MDCLinearProgress = new MDCLinearProgress(progressEl);
        progressEl.setAttribute('data-mdc-auto-init-state', 'initialized');
      }
      progressEl.MDCLinearProgress.foundation.setProgress(progress / 100);
    });

    self.dropzone.on('success', (file) => {
      const action = (new URL(self.getAttribute('action'))).pathname.substr(1);
      const bucket = self.querySelector('input[name="bucket"]').getAttribute('value');
      const key = createKey(file, action, bucket);

      const uploadParams = {
        bucket,
        file_name: file.name,
        key,
        file_size: file.size,
        file_content_type: file.type
      };

      const mergedParams = { ...params, ...uploadParams };

      Rails.ajax({
        url: url || '/uploads',
        type: 'POST',
        dataType: 'script',
        data: serialize({ upload: mergedParams, uuid: file.upload.uuid }),
        success: () => {
          dispatchUploadEvent('dropzone:fileUploaded', file.name);
        },
        complete: (xhr) => {
          Rails.fire(document.body, 'ajax:complete', [xhr]);
        }
      });
    });
  });

  function dispatchUnloadEvent() {
    if (self.dropzone.getUploadingFiles().length === 0) return;

    return I18n.t('js.uploads.pending_uploads'); // eslint-disable-line
  }

  window.onbeforeunload = dispatchUnloadEvent;
}, () => {
  window.onbeforeunload = null;
});
