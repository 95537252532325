import onmount from 'onmount';
import Rails from '@rails/ujs';

onmount('[data-js-bubblesheet-subquestion]', function () {
  const form = this.querySelector('form');
  const pointsWrapper = this.querySelector('[data-js-subquestion-points-wrapper]');
  const pointsEl = this.querySelector('[data-js-subquestion-points');
  const choicePointsToggle = this.querySelector('[data-js-toggle-points]');
  const choicePointWrapperEls = this.querySelectorAll('[data-js-choice-points-wrapper]');
  const choicePointEls = this.querySelectorAll('[data-js-choice-points]');
  const choiceCorrectEls = this.querySelectorAll('[data-js-choice-correct]');

  function handlePointsToggleClick() {
    choicePointWrapperEls.forEach((wrapper) => wrapper.classList.toggle('d-none'));
  }

  function handleChoiceCorrectChange() {
    const choice = this.closest('[data-js-choice');
    const choicePoints = choice.querySelector('[data-js-choice-points]');

    if (this.checked) {
      pointsWrapper.MDCTextField.disabled = false;
      const subquestionPoints = parseFloat(pointsEl.value) || 1;
      pointsEl.numeric.set(subquestionPoints);
      pointsEl.setAttribute('data-points', subquestionPoints);
      choicePoints.numeric.set(subquestionPoints);
    } else if (!form.querySelector('[data-js-choice-points]:checked, [data-js-choice-correct]:checked')) {
      pointsWrapper.MDCTextField.disabled = true;
      pointsEl.numeric.set(0);
      pointsEl.setAttribute('data-points', 0);
      choicePoints.numeric.set(0);
    } else {
      choicePoints.numeric.set(0);
    }

    Rails.fire(form, 'submit');
  }

  function handleChoicePointsFocus() {
    this.select();
  }

  function handleChoicePointsChange() {
    Rails.fire(form, 'submit');

    const points = parseFloat(this.value);
    const choice = this.closest('[data-js-choice');

    if (points > 0) {
      choice.querySelector('[data-js-choice-correct]').checked = true;
    } else if (points < 0) {
      choice.querySelector('[data-js-choice-correct]').checked = true;
    } else {
      choice.querySelector('[data-js-choice-correct]').checked = false;
    }
  }

  function handleSubquestionPointsFocus() {
    this.select();
  }

  function handleSubquestionPointsChange() {
    if (!this.value) this.numeric.set(0);

    const oldPoints = parseFloat(this.getAttribute('data-points'));
    const ratio = parseFloat(this.value) / oldPoints;

    choicePointEls.forEach((choice) => {
      choice.numeric.set(parseFloat(choice.value) * ratio || 0);
    });

    this.setAttribute('data-points', this.value);
    Rails.fire(form, 'submit');
  }

  if (choicePointsToggle) choicePointsToggle.addEventListener('click', handlePointsToggleClick);
  choicePointEls.forEach((input) => {
    input.addEventListener('focusin', handleChoicePointsFocus);
    input.addEventListener('change', handleChoicePointsChange);
  });

  choiceCorrectEls.forEach((input) => {
    input.addEventListener('change', handleChoiceCorrectChange);
  });

  pointsEl.addEventListener('focusin', handleSubquestionPointsFocus);
  pointsEl.addEventListener('change', handleSubquestionPointsChange);
});
