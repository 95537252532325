function addAction(snackbar, href, label, customizer) {
  const actionButton = document.createElement('a');
  actionButton.setAttribute('href', href);
  actionButton.innerHTML = `<span>${label}</span>`;
  if (customizer) customizer(actionButton);
  snackbar.append(actionButton);
}

export default function (message, alert = false, href = null, label = null, customizer = null) {
  const snackbar = document.createElement('div');
  snackbar.classList.add('appear-then-fade', 'd-flex', 'flex-items-center', 'gap-3', 'snackbar');
  if (alert) snackbar.classList.add('snackbar--error');
  snackbar.setAttribute('data-cy', 'snackbar');
  snackbar.dataset.turboTemporary = true;
  snackbar.dataset.controller = 'interaction';
  snackbar.dataset.action = 'animationend->interaction#remove';
  snackbar.innerHTML = `<span>${message}</span>`;

  if (href && label) addAction(snackbar, href, label, customizer);

  document.body.appendChild(snackbar);
}
