/* global I18n */

import onmount from 'onmount';
import TimeMe from 'timeme.js/timeme';
import Rails from '@rails/ujs';
import snackbar from '@components/snackbar';
import { showScore, updatePaginationTile, showEditedBy } from '@modules/grading/scoring';

onmount('[data-js-choices-panel]', function () {
  const self = this;
  const { editable, submissionId } = this.dataset;
  if (editable === 'false') return;

  const inputOptions = this.querySelectorAll('[data-js-choice] input[type="radio"], input[type="checkbox"]');
  const submissionForm = this.querySelector(`form#submission_${submissionId}`);
  const clearAnswer = this.querySelector('[data-js-clear-answer]');
  const gradingTimeInput = self.querySelector('[data-js-grading-time]');

  function submitForm() {
    gradingTimeInput.value = parseFloat(gradingTimeInput.value || 0) + TimeMe.getTimeOnCurrentPageInSeconds();

    Rails.fire(submissionForm, 'submit');
  }

  function removeToggleEffect() {
    inputOptions.forEach((inputOption) => {
      inputOption.closest('[data-js-choice]').classList.remove('criterium-selector--toggling');
      inputOption.disabled = false;
    });
  }

  function updateClearAnswerButton() {
    let answered = false;

    inputOptions.forEach((inputOption) => {
      if (inputOption.checked === true) {
        answered = true;
      }
    });

    if (answered) {
      clearAnswer.classList.remove('d-none');
    } else {
      clearAnswer.classList.add('d-none');
    }
  }

  function handleClearAnswerClick() {
    if (!inputOptions.length) return;

    inputOptions.forEach((inputOption) => {
      inputOption.checked = false;
      inputOption.closest('[data-js-choice]').classList.remove('criterium-selector--selected');
    });

    submitForm();
  }

  function handleInputOptionChange(e) {
    if (e.target.type === 'radio') {
      if (e.target.closest('[data-js-choice]').classList.contains('criterium-selector--selected')) return;

      inputOptions.forEach((radioOption) => {
        if (e.target !== radioOption) {
          radioOption.checked = false;
          radioOption.closest('[data-js-choice]').classList.remove('criterium-selector--selected');
        }
      });
    }

    if (e.target.checked) {
      e.target.closest('[data-js-choice]').classList.add('criterium-selector--selected');
    } else {
      e.target.closest('[data-js-choice]').classList.remove('criterium-selector--selected');
    }

    submitForm();

    inputOptions.forEach((inputOption) => {
      inputOption.closest('[data-js-choice]').classList.add('criterium-selector--toggling');
      inputOption.disabled = true;
    });
  }

  function handleSuccess(e) {
    if (!e.target.hasAttribute('data-js-update-score')) return;

    const data = e.detail[0];
    showScore(data.id, data.score);
    updatePaginationTile(data.id, data.graded, data.score, data.points);
    showEditedBy(self, data.edited_by);
    updateClearAnswerButton();
    removeToggleEffect();
  }

  function handleError() {
    if (navigator.onLine) {
      snackbar(I18n.t('js.gradings.failed_score_save'), true);
    } else {
      snackbar(I18n.t('js.gradings.no_internet'), true);
    }
  }

  function handleKeyUp(e) {
    if (self.closest('.d-none')) return;
    if (e.metaKey || e.ctrlKey || e.shiftKey || e.altKey) return;
    if (['INPUT', 'TEXTAREA', 'MATH-FIELD'].includes(e.target.tagName)) return;
    if (['radio', 'checkbox'].includes(e.target.type)) return;
    if (e.target.closest('.redactor-in')) return;
    if (e.target.closest('.x-spreadsheet')) return;

    if (e.key === '0' || e.key === '§') {
      clearAnswer.click();
    } else if (inputOptions.length && ['1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key)) {
      inputOptions[parseInt(e.key, 10) - 1].click();
    }
  }

  this.handleKeyUp = handleKeyUp;

  if (clearAnswer) clearAnswer.addEventListener('click', handleClearAnswerClick);

  if (inputOptions.length) {
    inputOptions.forEach((inputOption) => {
      inputOption.addEventListener('click', handleInputOptionChange.bind(inputOption));
    });
  }

  self.addEventListener('ajax:success', handleSuccess);
  submissionForm.addEventListener('ajax:error', handleError);

  document.addEventListener('keyup', this.handleKeyUp);

  TimeMe.resetAllRecordedPageTimes();
  TimeMe.startTimer();
}, function () {
  document.removeEventListener('keyup', this.handleKeyUp);
});
