import onmount from 'onmount';

onmount('[data-js-notification-banner]', function () {
  const banner = this;
  const closeButton = this.querySelector('[data-js-close]');

  function removeParameters() {
    const url = window.location.origin + window.location.pathname;
    window.history.pushState('', document.title, url);
  }

  function closeBanner() {
    banner.remove();
    removeParameters();
  }

  closeButton.addEventListener('click', closeBanner);
});
