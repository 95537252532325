/* global Channels */

import onmount from 'onmount';
import consumer from './consumer';

onmount('[data-js-quiz-participants]', function () {
  const notStartedCount = this.querySelector('[data-js-not-started-count]');
  const busyCount = this.querySelector('[data-js-busy-count]');
  const submittedCount = this.querySelector('[data-js-submitted-count]');

  function moveResult(data) {
    const el = document.querySelector(`
      [data-js-user][data-user-id='${data.user_id}'],
      [data-js-group][data-group-id='${data.group_id}']`);
    if (el && el.closest(`[data-js-${data.status}]`)) {
      el.outerHTML = data.template;
    } else {
      if (el) el.remove();
      document.querySelector(`[data-js-${data.status}]`).insertAdjacentHTML('beforeend', data.template);
    }

    onmount();
  }

  function updateHeaders(data) {
    notStartedCount.innerHTML = data.not_started_count;
    busyCount.innerHTML = data.busy_count;
    submittedCount.innerHTML = data.submitted_count;
  }

  const quizParticipantsSubscription = consumer.subscriptions.create({
    channel: 'QuizParticipantsChannel',
    assignment_id: this.dataset.assignmentId
  }, {
    disconnected() {
      // snackbar(I18n.t('js.general.connection_lost'));
    },

    received(data) {
      moveResult(data);
      updateHeaders(data);
    }
  });

  Channels.quizParticipantsSubscription = quizParticipantsSubscription;
}, () => {
  Channels.quizParticipantsSubscription.unsubscribe();
});
