import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="cocoon"
export default class extends Controller {
  static targets = ['addButton', 'input'];

  reportValidity() {
    const invalidInput = this.inputTargets.find((input) => !input.checkValidity());
    if (invalidInput) return invalidInput.reportValidity();

    return true;
  }

  focusLast() {
    const element = this.inputTargets[this.inputTargets.length - 1];
    element.focus();
  }

  addItem(e) {
    if (!this.hasAddButtonTarget) return;

    if (!this.reportValidity()) return;

    e.preventDefault();
    this.addButtonTarget.click();
  }
}
