/* global I18n */

import onmount from 'onmount';
import { debounce } from '@modules/custom';

onmount('[data-js-quiz-accessibility]', function () {
  const self = this;
  const warningEl = this.querySelector('[data-js-quiz-accessibility-warning]');

  async function getAccessibilityJs() {
    const module = await import(/* webpackChunkName: "accessibilityjs" */ '@vendor/accessibilityjs');
    return module.scanForProblems;
  }

  function logError(error) {
    error.element.classList.add('accessibility-error');

    const warningText = `<span class='accessibility-warning'>${error.message}</span>`;
    error.element.insertAdjacentHTML('afterend', warningText);
  }

  function checkAccessibility() {
    getAccessibilityJs().then((scanForProblems) => {
      const options = {
        ignoreClass: 'ace_editor'
      };
      const errors = scanForProblems(self, logError, options);
      const errorsAmount = Object.values(errors).flat().length;

      if (errorsAmount) {
        warningEl.innerHTML = "<i class='material-icons mr-1'>settings_suggest</i>" +
                             `${I18n.t('js.assignments.accessibility.issues', { count: errorsAmount })}`;
      } else {
        warningEl.innerHTML = '';
      }
    });
  }
  this.checkAccessibility = debounce(checkAccessibility, 250);

  function showAccessibilityWarnings() {
    self.classList.add('show-accessibility-issues');

    const errorEl = self.querySelector('.accessibility-error');
    errorEl.scrollIntoView();
  }

  document.addEventListener('quizPreview:loaded', this.checkAccessibility);
  if (warningEl) warningEl.addEventListener('click', showAccessibilityWarnings);
}, function () {
  document.removeEventListener('quizPreview:loaded', this.checkAccessibility);
});
