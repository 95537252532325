/* global I18n */

import onmount from 'onmount';
import TimeMe from 'timeme.js/timeme';
import snackbar from '@components/snackbar';
import Rails from '@rails/ujs';
import { displayUpdatingStatus } from '@modules/quiz/saving';
import { updateAnsweredSubmissions, syncDrawer } from '@modules/quiz/navigating';
import { clearTimeouts, throttle } from '@modules/custom';
import { showFormErrors } from '@modules/form-responses';

onmount('[data-js-quiz-panel]', function () {
  this.requestCount = 0;
  this.saveTimeouts = [];
  this.heartbeatTimeouts = [];

  const self = this;
  const forms = this.querySelectorAll('form');
  const SAVE_INTERVAL = 30 * 1000;
  const HEARTBEAT_INTERVAL = 30 * 60 * 1000;
  let mdcSnackbar;

  function handleFormSubmit() {
    this.setAttribute('data-saving', true);
    self.requestCount += 1;
    const workingTime = self.querySelector('input[name="submission[working_time]"]');
    if (workingTime) workingTime.remove();

    const time = TimeMe.getTimeOnCurrentPageInSeconds();
    const input = document.createElement('input');
    input.type = 'hidden';
    input.setAttribute('name', 'submission[working_time]');
    input.value = time;
    this.append(input);

    TimeMe.resetAllRecordedPageTimes();
    TimeMe.startTimer();
  }

  function handleFormComplete() {
    this.setAttribute('data-saving', false);
    self.requestCount -= 1;
  }

  function autoSave() {
    [...self.querySelectorAll('form[data-saved="false"]')].forEach((form) => {
      form.setAttribute('data-saved', true);
      displayUpdatingStatus(form);
      Rails.fire(form, 'submit');
    });

    clearTimeouts(self.saveTimeouts);
    self.saveTimeouts.push(setTimeout(autoSave, SAVE_INTERVAL));
  }

  function heartbeat() {
    Rails.ajax({
      url: '/heartbeat',
      type: 'GET',
      dataType: 'json',
      error: (data, responseText, xhr) => {
        if (xhr && xhr.status === 401) {
          snackbar(I18n.t('js.general.session_timedout'), true);
          setTimeout(() => {
            window.location.reload();
          }, 5 * 1000);
        }
      }
    });

    clearTimeouts(self.heartbeatTimeouts);
    self.heartbeatTimeouts.push(setTimeout(heartbeat, HEARTBEAT_INTERVAL));
  }

  function restartTimeAgo(form) {
    const element = form.querySelector('[data-js-time-ago]');
    if (!element) return;

    const restartEvent = new CustomEvent('timeago:restart');
    element.dispatchEvent(restartEvent);
  }

  function dispatchAnsweredEvent() {
    const submissionAnsweredEvent = new CustomEvent('submission:answered');
    document.dispatchEvent(submissionAnsweredEvent);
  }

  function handleFileLoading() {
    displayUpdatingStatus(this);
  }

  function submissionUpdated(e) {
    restartTimeAgo(this);

    if (e.detail) updateAnsweredSubmissions(e.detail);
  }

  function handleFileSuccess(e) {
    if (!e.target.hasAttribute('data-js-submission-form')) return;

    const data = e.detail;

    updateAnsweredSubmissions(data);
    restartTimeAgo(this);
    dispatchAnsweredEvent();
  }

  function handleFormSuccess(e) {
    if (!e.target.hasAttribute('data-js-submission-form')) return;

    const [data] = e.detail;

    const timer = document.querySelector('[data-js-timer]');
    if (timer && data.time_remaining) timer.setAttribute('data-time-remaining', data.time_remaining);

    if (e.target.getAttribute('data-pending-save') === 'true') {
      Rails.fire(e.target, 'submit');
      e.target.setAttribute('data-saved', true);
      e.target.setAttribute('data-pending-save', false);
    } else {
      updateAnsweredSubmissions(data);
      restartTimeAgo(this);
      dispatchAnsweredEvent();
      syncDrawer();
    }
  }

  function handleTimeLimitExpired(e, formEl) {
    e.detail[0].base = e.detail[0].time_limit;
    showFormErrors(e);
    formEl.setAttribute('data-saved', true);
    formEl.querySelector('[data-js-time-ago]').innerHTML = I18n.t('js.subsets.question_timer_expired');
  }

  function handleFormError(e) {
    const [data, , xhr] = e.detail;
    if (data.time_limit) {
      handleTimeLimitExpired(e, this);
    } else if (xhr.status === 403) {
      showFormErrors(e);
      this.setAttribute('data-saved', true);
    } else {
      showFormErrors(e);
      this.setAttribute('data-saved', false);
    }
  }

  function handleClientOnline() {
    if (mdcSnackbar) mdcSnackbar.close();
  }
  this.handleClientOnline = handleClientOnline;

  function handleClientOffline() {
    if (!mdcSnackbar || !mdcSnackbar.isOpen) {
      mdcSnackbar = snackbar(I18n.t('js.general.connection_lost'), true);
    }
  }
  this.handleClientOffline = handleClientOffline;

  forms.forEach((form) => {
    form.addEventListener('submit', handleFormSubmit);
    form.addEventListener('ajax:success', handleFormSuccess);
    form.addEventListener('ajax:error', handleFormError);
    form.addEventListener('ajax:complete', handleFormComplete);
    form.addEventListener('file:loading', handleFileLoading);
    form.addEventListener('file:success', handleFileSuccess);
    form.addEventListener('submission:updated', submissionUpdated);
  });

  window.addEventListener('online', this.handleClientOnline);
  window.addEventListener('offline', this.handleClientOffline);

  this.addEventListener('quiz:save', throttle(autoSave, 3000, true));

  this.saveTimeouts.push(setTimeout(autoSave, SAVE_INTERVAL));
  this.heartbeatTimeouts.push(setTimeout(heartbeat, HEARTBEAT_INTERVAL));

  TimeMe.resetAllRecordedPageTimes();
  TimeMe.startTimer();
}, function () {
  window.removeEventListener('online', this.handleClientOnline);
  window.removeEventListener('offline', this.handleClientOffline);

  clearTimeouts(this.saveTimeouts);
  clearTimeouts(this.heartbeatTimeouts);
});
