/* eslint-disable no-underscore-dangle */
/* global Redactor, I18n */

(function ($R) {
  $R.add('plugin', 'fill', {
    translations: {
      en: {
        optionList: 'option list',
        gap: 'Gap',
        insert: 'Insert',
        delete: 'Delete',
        'add-gap': 'New '
      }
    },
    init(app) {
      this.app = app;
      this.lang = app.lang;
      this.opts = app.opts;
      this.toolbar = app.toolbar;
      this.component = app.component;
      this.insertion = app.insertion;
      this.offset = app.offset;
      this.inspector = app.inspector;
      this.selection = app.selection;
      this.container = app.container;
      this.optionLists = this.opts.optionLists;
    },

    // public
    start() {
      this._buildActionBar();
    },
    _buildActionBar() {
      const element = `
        <div class="d-flex flex-justify-end mx-3 mb-3">
          <div class="split-button position-relative" data-controller="dropdown">
            <button type="button" class="split-button__button button--small" data-action="click->option-list--new#get">
              ${I18n.t('js.option_lists.new_gap')}
            </button>

            <button type="button" class="split-button__toggle button--small material-icons"
                    data-option-lists-target="restoreButton"
                    data-action="click->option-lists#saveOffset click->dropdown#toggle click@window->dropdown#hide">
              arrow_drop_down
            </button>

            <div class="dropdown-menu gap-1 hidden" style="top: 100%" data-dropdown-target="menu" tabindex="-1">
              <div class="f4 text-bold py-2 px-3">${I18n.t('js.option_lists.restore_gap')}</div>
              <ul class="dropdown-list" data-option-lists-target="restoreList"></ul>
            </div>
          </div>
        </div>
      `;
      this.app.container.getElement().append(element);
    },
    insert(item) {
      const { name } = item;
      const optionList = this.component.create('optionList');
      optionList.html(name);
      optionList.attr('data-id', item.id);

      if (this.insertionOffset !== undefined) {
        this.offset.set(this.insertionOffset);
      }
      this.insertion.insertRaw(optionList);
    },
    saveOffset() {
      if (this.selection.is()) {
        this.insertionOffset = this.offset.get();
      }
    }
  });
}(Redactor));
(function ($R) {
  $R.add('class', 'optionList.component', {
    mixins: ['dom', 'component'],
    init(app, el) {
      this.app = app;
      this.utils = app.utils;

      // init
      return (el && el.cmnt !== undefined) ? el : this._init(el);
    },
    // public
    getData() {
      return {
        type: this._getType()
      };
    },

    // private
    _init(el) {
      el = el || '<span>';

      this.parse(el);
      this._initWrapper();
    },
    _getType() {
      const text = this.text().trim();

      return this.utils.removeInvisibleChars(text);
    },
    _initWrapper() {
      this.addClass('redactor-component');
      this.attr({
        'data-redactor-type': 'optionList',
        tabindex: '-1',
        contenteditable: false
      });
    }
  });
}(Redactor));
