/* global I18n */

import onmount from 'onmount';
import Rails from '@rails/ujs';
import snackbar from '@components/snackbar';
import {
  showScore, updatePaginationTile, showEditedBy
} from '@modules/grading/scoring';

onmount('[data-js-adjustments-wrapper]', function () {
  const self = this;
  this.panel = this.closest('[data-js-grading-panel]');
  const { submissionId } = this.panel.dataset;
  if (this.dataset.editable === 'false') return;

  function fetchScores() {
    Rails.ajax({
      url: `/submissions/${submissionId}/scores`,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        showScore(data.id, data.score);
        updatePaginationTile(data.id, data.graded, data.score, data.points);
        showEditedBy(self.panel, data.edited_by);
      },
      error: () => {
        if (navigator.onLine) {
          snackbar(I18n.t('js.gradings.failed_score_save'), true);
        } else {
          snackbar(I18n.t('js.gradings.no_internet'), true);
        }
      }
    });
  }
  this.fetchScores = fetchScores;

  this.panel.addEventListener('adjustmentChanged', this.fetchScores);
}, function () {
  this.panel.removeEventListener('adjustmentChanged', this.fetchScores);
});
