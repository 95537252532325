/* global I18n */

import onmount from 'onmount';
import refreshTextFieldLabel from '@components/text_field';
import { handleTimeChange } from '@modules/handle-time-change';

onmount('[data-js-flatpickr]', function () {
  const dateFieldEls = this.querySelectorAll('[data-js-flatpickr-date]');
  const timeFieldEls = this.querySelectorAll('[data-js-flatpickr-time]');
  const datetimeFieldEls = this.querySelectorAll('[data-js-flatpickr-datetime]');
  const inline = this.hasAttribute('data-flatpickr-inline');
  let language = I18n.locale;
  if (language === 'nl_vo') language = 'nl';
  if (language === 'en') language = 'default';

  async function getFlatpickr() {
    const [module] = await Promise.all([
      import(/* webpackChunkName: "flatpickr" */ 'flatpickr'),
      import(/* webpackChunkName: "flatpickr" */ 'flatpickr/dist/l10n/nl'),
      import(/* webpackChunkName: "flatpickr" */ 'flatpickr/dist/l10n/de'),
      import(/* webpackChunkName: "flatpickr" */ 'flatpickr/dist/l10n/fr'),
      import(/* webpackChunkName: "flatpickr" */ 'flatpickr/dist/l10n/es'),
      import(/* webpackChunkName: "flatpickr" */ 'flatpickr/dist/l10n/pt'),
      import(/* webpackChunkName: "flatpickr" */ 'flatpickr/dist/l10n/it'),
      import(/* webpackChunkName: "flatpickr" */ 'flatpickr/dist/l10n/pl')
    ]);
    return module.default;
  }

  function handleDateChange(selectedDates, dateStr, instance) {
    const mdcTextField = instance.input.closest('.mdc-text-field');
    if (mdcTextField) refreshTextFieldLabel(mdcTextField);
  }

  getFlatpickr().then((flatpickr) => {
    let dateFields = flatpickr(dateFieldEls, {
      allowInput: true,
      altInput: true,
      altFormat: 'd-m-Y',
      inline,
      locale: language
    });
    if (!Array.isArray(dateFields)) dateFields = [dateFields];

    let datetimeFields = flatpickr(datetimeFieldEls, {
      allowInput: true,
      altInput: true,
      altFormat: 'd-m-Y H:i',
      enableTime: true,
      time_24hr: true,
      dateFormat: 'Y-m-d H:i',
      inline,
      locale: language,
      minuteIncrement: 1
    });
    if (!Array.isArray(datetimeFields)) datetimeFields = [datetimeFields];

    flatpickr(timeFieldEls, {
      allowInput: true,
      enableTime: true,
      noCalendar: true,
      time_24hr: true,
      dateFormat: 'H:i'
    });

    dateFields.forEach((dateField) => {
      dateField.config.onChange.push(handleDateChange);
    });

    datetimeFields.forEach((datetimeField) => {
      datetimeField.config.onChange.push(handleDateChange);
    });

    timeFieldEls.forEach((timeFieldEl) => {
      timeFieldEl.addEventListener('keyup', handleTimeChange);
    });
  });
});
