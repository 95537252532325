export default function getQuestionPositions(key, identifierId) {
  const positions = localStorage.getItem(key);
  if (positions) return Promise.resolve(JSON.parse(positions));

  return fetch(`/position/identifiers/${identifierId}.json`)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      localStorage.setItem(key, JSON.stringify(data));
      return data;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('There was a problem with the fetch operation:', error);
      return null;
    });
}
