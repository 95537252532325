import onmount from 'onmount';

onmount('[data-js-conversation-settings]', function () {
  const closeConversation = this.querySelector('[data-js-close-conversation]');
  const nextQuestion = this.querySelector('[data-js-next-question]');
  const sendButton = this.querySelector('[data-js-send-message]');
  const draftButton = this.querySelector('[data-js-send-draft]');
  const nextButton = document.querySelector('[data-js-next-item]');

  let closeConversationStatus;
  let nextQuestionStatus;

  function handleCloseConversationClick() {
    localStorage.setItem('close-conversation', this.checked);
  }

  function setCloseConversationCheckbox() {
    if (localStorage.getItem('close-conversation')) {
      closeConversationStatus = localStorage.getItem('close-conversation') === 'true';
    } else {
      closeConversationStatus = true;
    }

    closeConversation.checked = closeConversationStatus;
  }

  function handleNextQuestionClick() {
    localStorage.setItem('next-question', this.checked);
  }

  function setNextQuestionCheckbox() {
    if (localStorage.getItem('next-question')) {
      nextQuestionStatus = localStorage.getItem('next-question') === 'true';
    } else {
      nextQuestionStatus = true;
    }

    nextQuestion.checked = nextQuestionStatus;
  }

  function handleSendButtonClick() {
    if (nextQuestion.checked && nextButton) nextButton.click();
  }

  if (closeConversation) {
    setCloseConversationCheckbox();
    closeConversation.addEventListener('click', handleCloseConversationClick);
  }

  if (nextQuestion) {
    if (window.location.href.includes('grading') && nextButton) {
      setNextQuestionCheckbox();
    } else {
      nextQuestion.parentElement.parentElement.classList.add('d-none');
    }
    nextQuestion.addEventListener('click', handleNextQuestionClick);
  }

  if (sendButton) sendButton.addEventListener('click', handleSendButtonClick);
  if (draftButton) draftButton.addEventListener('click', handleSendButtonClick);
});
