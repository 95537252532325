import onmount from 'onmount';
import { showFormErrors } from '@modules/form-responses';

onmount('[data-js-annotation-comment]', function () {
  const self = this;
  const annotationCommentPanel = this.closest('[data-js-annotation-comment-panel]');
  const form = this.querySelector('form');
  const cancel = this.querySelector('[data-js-cancel]');

  function handleCancelClick() {
    if (annotationCommentPanel.querySelectorAll('form').length > 1) {
      self.classList.add('d-none');
    } else {
      const annotationBlurEvent = new CustomEvent('annotation:blur');
      document.dispatchEvent(annotationBlurEvent);
    }
  }

  function formErrorListener(event) {
    if (event.target === form) showFormErrors(event);
  }

  if (form) form.addEventListener('ajax:error', formErrorListener);
  if (cancel) cancel.addEventListener('click', handleCancelClick);
});
