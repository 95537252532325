/* global I18n */

import Rails from '@rails/ujs';
import { serialize } from '@modules/custom';
import { redactorOptions } from '@redactor/options';
import { getRedactor } from '@redactor/helpers';
import { removeKatexFromHtml } from '@redactor/remove-katex';

const MAX_FILE_SIZE = 25 * 1000 * 1000;
const MAX_FILE_SIZE_IN_TEXT = '25 MB';

function cleanHtml(html) {
  const cleanedHtml = html.replace('redactor-component-active', '');
  return cleanedHtml;
}

function escapeHtml(htmlStr) {
  return htmlStr.replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;');
}

function wrapPreInCode(html) {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = html;
  if (!wrapper.querySelector('pre')) return html;

  wrapper.querySelectorAll('pre').forEach((pre) => {
    const textNodes = pre.innerText.split(/\r?\n/);
    const wrappedNodes = [];
    for (let j = 0; j < textNodes.length; j += 1) {
      wrappedNodes.push(`<code>${escapeHtml(textNodes[j])}</code>`);
    }
    pre.innerHTML = wrappedNodes.join('\n');
  });

  return wrapper.innerHTML;
}

function removeValidationWarning(editor) {
  editor.statusbar.remove('warning');
  editor.container.getElement().removeClass('invalid');
}

function fileSizeTooBig(files) {
  for (let iter = 0; iter < files.length; iter += 1) {
    if (files[iter].size > MAX_FILE_SIZE) {
      return {
        error: true,
        message: I18n.t('js.uploads.file_too_large', { filesize: MAX_FILE_SIZE_IN_TEXT })
      };
    }
  }

  return false;
}

function getPresignedUrl(url, filename) {
  return new Promise((resolve, reject) => {
    Rails.ajax({
      url,
      type: 'GET',
      data: serialize({ filename: filename }), // eslint-disable-line
      success: (data) => resolve(data),
      error: (data) => reject(data.statusText)
    });
  });
}

function uploadToObjectStore(file, url, progressFn = null) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url, true);

    xhr.upload.onprogress = function (event) {
      if (typeof progressFn === 'function') {
        progressFn(event);
      }
    };

    xhr.onload = function () {
      if (this.status === 201) {
        resolve(xhr.getResponseHeader('ETAG'), xhr.response);
      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      }
    };

    xhr.onerror = function () {
      reject({
        status: this.status,
        statusText: xhr.statusText
      });
    };

    xhr.send(file);
  });
}

function createUpload(file, data, id, type, column, repoId, etag, dimensions, metadata) {
  return new Promise((resolve, reject) => {
    Rails.ajax({
      url: '/redactor_images.json',
      type: 'POST',
      data: serialize({
        redactor_image: {
          bucket: data.bucket,
          file_content_type: file.type,
          file_name: file.name,
          file_size: file.size,
          key: data.key,
          width: dimensions.width,
          height: dimensions.height,
          etag,
          metadata,
          redactor_imageable_column: column,
          redactor_imageable_id: id,
          redactor_imageable_type: type,
          repo_id: repoId
        }
      }),
      success: (response) => {
        resolve({
          id: response.id,
          name: response.file_name,
          url: response.download_url
        });
      },
      error: (response) => {
        reject({
          status: response.status,
          statusText: response.responseText
        });
      }
    });
  });
}

function isFileImage(file) {
  return file && file.type.split('/')[0] === 'image';
}

function getHeightAndWidthFromDataUrl(file) {
  return new Promise((resolve) => {
    if (!isFileImage(file)) {
      resolve({
        height: null,
        width: null
      });
    }

    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width
      });
    };
    const dataURL = window.URL.createObjectURL(file);
    img.src = dataURL;
  });
}

function uploadFile(file, editor, metadata) {
  return new Promise((resolve, reject) => {
    const url = '/redactor_images/presigned_upload_url.json';
    const {
      id,
      type,
      column,
      repoId
    } = editor.dataset;
    if (!(type && column)) {
      reject({ status: '400', statusText: 'Missing required id, type and column' });
    }

    getPresignedUrl(url, file.name).then((data) => {
      getHeightAndWidthFromDataUrl(file).then((dimensions) => {
        uploadToObjectStore(file, data.put_url).then((etag) => {
          createUpload(file, data, id, type, column, repoId, etag, dimensions, metadata).then((response) => {
            resolve(response);
          });
        });
      }).catch((err) => {
        reject({ status: err.status, statusText: err.statusText });
      });
    });
  });
}

function uploadFiles(files, $editor, upload) {
  const promiseArray = [];
  const metadata = upload.p.metadata || { source: upload.p.name, timestamp: Date.now() };

  for (let iter = 0; iter < files.length; iter += 1) {
    const file = files[iter];
    promiseArray.push(uploadFile(file, $editor, metadata));
  }

  return Promise.all(promiseArray).then((processedFiles) => {
    const result = {};

    for (let iter = 0; iter < processedFiles.length; iter += 1) {
      result[`file-${iter}`] = processedFiles[iter];
    }

    return result;
  });
}

getRedactor().then((Redactor) => {
  Redactor.options = {
    plugins: redactorOptions.plugins,
    buttons: redactorOptions.buttons,
    autoparseStart: false,
    markup: 'div',
    lang: redactorOptions.language,
    linkNewTab: true,
    linkTarget: '_blank',
    linkNofollow: true,
    imageLink: false,
    imageCaption: false,
    imageResizable: true,
    imagePosition: true,
    formattingAdd: {
      div: {
        title: '## paragraph ##',
        api: 'module.block.format',
        args: {
          tag: 'div'
        }
      }
    },
    formatting: ['div', 'pre', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    pasteBlockTags: [
      'div', 'pre', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'table', 'tbody',
      'thead', 'tfoot', 'th', 'tr', 'td', 'ul', 'ol', 'li', 'blockquote',
      'p', 'figure', 'figcaption'
    ],
    pasteLinkTarget: '_blank',
    callbacks: {
      upload: {
        error: (response) => {
          console.error(response.message); // eslint-disable-line no-console
        }
      },
      syncing: (html) => {
        const cleanedHtml = cleanHtml(html);
        const wrappedHtml = wrapPreInCode(cleanedHtml);

        if (!wrappedHtml.includes('class="katex"')) {
          return wrappedHtml;
        }

        return removeKatexFromHtml(wrappedHtml);
      },
      synced() {
        return removeValidationWarning(this);
      }
    },
    imageUpload: (fileformData, files, event, upload) => new Promise((resolve) => {
      const response = fileSizeTooBig(files);
      if (response) {
        resolve(upload.complete(response));
        return;
      }

      const editor = upload.editor.element.rootElement;
      uploadFiles(files, editor, upload).then((res) => resolve(upload.complete(res)));
    }),
    fileUpload: (fileformData, files, event, upload) => new Promise((resolve) => {
      const response = fileSizeTooBig(files);
      if (response) {
        resolve(upload.complete(response));
        return;
      }

      const editor = upload.editor.element.rootElement;
      uploadFiles(files, editor, upload).then((res) => resolve(upload.complete(res)));
    }),
    imageManagerJson: (redactor) => {
      const el = redactor.app.rootElement;
      return el.dataset.imageManager;
    },
    fileManagerJson: (redactor) => {
      const el = redactor.app.rootElement;
      return el.dataset.fileManager;
    }
  };
});
