/* global google, I18n */

import onmount from 'onmount';
import Rails from '@rails/ujs';
import { debounce } from '@modules/custom';

onmount('[data-js-domains-chart]', function () {
  const self = this;
  const { url } = this.dataset;
  const chart = this.querySelector('[data-js-chart]');
  let data;

  function chartOptions() {
    return {
      vega: {
        $schema: 'https://vega.github.io/schema/vega/v5.json',
        width: self.clientWidth * 0.65,

        signals: [
          { name: 'barHeight', value: 60 },
          { name: 'height', update: `(barHeight) * ${data[0].values.length}` }
        ],

        data,

        scales: [
          {
            name: 'domains',
            type: 'band',
            range: 'height',
            domain: { data: 'student', field: 'id' }
          },
          {
            name: 'domain_names',
            type: 'ordinal',
            domain: data[0].values.map((x) => x.id),
            range: data[0].values.map((x) => x.name)
          },
          {
            name: 'scores',
            type: 'linear',
            range: 'width',
            domain: [0, 1]
          },
          {
            name: 'scores2',
            type: 'linear',
            range: 'width',
            domain: [0, 1]
          },
          {
            name: 'comparison_legend',
            type: 'ordinal',
            domain: [
              I18n.t('js.results.domains.participant_score'),
              I18n.t('js.results.domains.median_percentile_reference_group'),
              I18n.t('js.results.domains.percentile_reference_group_range', { range: '5-95' }),
              I18n.t('js.results.domains.percentile_reference_group_range', { range: '25-75' })
            ],
            range: ['#FFF', '#FFF', '#FBD7C4', '#F18145']
          },
          {
            name: 'student_legend',
            type: 'ordinal',
            domain: [I18n.t('js.results.domains.score')]
          }
        ],

        legends: [
          {
            fill: data[1].values.length === 0 ? 'student_legend' : 'comparison_legend',
            orient: 'bottom',
            stroke: data[1].values.length === 0 ? 'student_legend' : 'comparison_legend',
            columns: 2,
            columnPadding: 40,
            rowPadding: 16,
            symbolSize: 196,
            symbolType: 'square',
            labelLimit: 0,
            labelFont: 'Nunito',
            labelFontSize: 12,
            labelOffset: 8,
            encode: {
              symbols: {
                update: {
                  stroke: [
                    { test: 'datum.index == 0', value: '#000' },
                    { test: 'datum.index == 1', value: '#D1D1D1' },
                    { signal: "scale('color', datum.label)" }
                  ],
                  opacity: [
                    { test: 'datum.index == 0 || datum.index == 1', value: 1 },
                    { value: 0.8 }
                  ],
                  shape: [
                    { test: 'datum.index == 0', value: 'circle' },
                    { test: 'datum.index == 1', value: 'M-0.25,-1H0.25V1H-0.25Z' },
                    { value: 'square' }
                  ],
                  strokeWidth: { value: 2 }
                }
              }
            }
          }
        ],

        axes: [
          {
            orient: 'bottom',
            scale: 'scores2',
            values: [0.125, 0.375, 0.625, 0.875],
            grid: true,
            gridColor: '#E8E8E8',
            labels: false,
            domain: false,
            ticks: false
          },
          {
            orient: 'bottom',
            scale: 'scores',
            title: `${I18n.t('js.results.domains.score')}`,
            titlePadding: 8,
            values: [0, 0.25, 0.50, 0.75, 1],
            zindex: 0,
            format: '%',
            labelFont: 'Nunito',
            labelFontSize: 12,
            labelPadding: 16,
            domain: false,
            grid: true,
            gridColor: '#D1D1D1',
            ticks: false,
            encode: {
              title: {
                update: {
                  fontSize: { value: 12 },
                  fontWeight: 400,
                  fill: { value: '#464646' }
                }
              }
            }
          },
          {
            orient: 'left',
            scale: 'domains',
            labelFont: 'Nunito',
            labelFontSize: 14,
            labelPadding: 16,
            labelLimit: self.clientWidth * 0.2,
            zindex: 1,
            domain: false,
            ticks: false,
            encode: {
              labels: {
                interactive: true,
                update: {
                  text: { signal: "scale('domain_names', datum.label)" },
                  tooltip: { signal: "scale('domain_names', datum.label)" }
                }
              }
            }
          }
        ],

        marks: [
          {
            type: 'group',

            encode: {
              enter: {
                yc: { scale: 'domains', field: 'id', band: 0.5 },
                height: { signal: 'barHeight' }
              }
            },

            marks: [
              {
                type: 'rect',
                from: { data: 'comparison' },
                encode: {
                  enter: {
                    fill: { value: '#FBD7C4' },
                    fillOpacity: { value: 0.8 },
                    tooltip: {
                      signal: `
                        {
                          '${I18n.t('js.results.domains.nth_percentile', { count: 5 })}': format(datum.min, '.00%'),
                          '${I18n.t('js.results.domains.nth_percentile', { count: 25 })}': format(datum.q1, '.00%'),
                          '${I18n.t('js.results.domains.median')}': format(datum.median, '.00%'),
                          '${I18n.t('js.results.domains.nth_percentile', { count: 75 })}': format(datum.q3, '.00%'),
                          '${I18n.t('js.results.domains.nth_percentile', { count: 95 })}': format(datum.max, '.00%')
                        }
                      `
                    }
                  },
                  update: {
                    yc: { scale: 'domains', field: 'id', band: 0.5 },
                    height: { signal: 'barHeight / 2' },
                    x: { scale: 'scores', field: 'min' },
                    x2: { scale: 'scores', field: 'max' }
                  }
                }
              },

              {
                type: 'rect',
                from: { data: 'comparison' },
                encode: {
                  enter: {
                    fill: { value: '#F18145' },
                    fillOpacity: { value: 0.8 },
                    tooltip: {
                      signal: `
                        {
                          '${I18n.t('js.results.domains.nth_percentile', { count: 5 })}': format(datum.min, '.00%'),
                          '${I18n.t('js.results.domains.nth_percentile', { count: 25 })}': format(datum.q1, '.00%'),
                          '${I18n.t('js.results.domains.median')}': format(datum.median, '.00%'),
                          '${I18n.t('js.results.domains.nth_percentile', { count: 75 })}': format(datum.q3, '.00%'),
                          '${I18n.t('js.results.domains.nth_percentile', { count: 95 })}': format(datum.max, '.00%')
                        }
                      `
                    }
                  },
                  update: {
                    yc: { scale: 'domains', field: 'id', band: 0.5 },
                    height: { signal: 'barHeight / 2' },
                    x: { scale: 'scores', field: 'q1' },
                    x2: { scale: 'scores', field: 'q3' }
                  }
                }
              },

              {
                type: 'rect',
                from: { data: 'comparison' },
                encode: {
                  update: {
                    fill: { value: '#FFF' },
                    height: { signal: 'barHeight / 2' },
                    width: { value: 2 },
                    x: { scale: 'scores', field: 'median' },
                    yc: { scale: 'domains', field: 'id', band: 0.5 }
                  }
                }
              },

              {
                type: 'rule',
                from: { data: 'student' },
                encode: {
                  enter: {
                    x1: { scale: 'scores', value: 0 },
                    x2: { scale: 'scores', field: 'score' },
                    y: { scale: 'domains', field: 'id', band: 0.5 },
                    stroke: { value: '#2E2E2E' },
                    strokeOpacity: { value: data[1].values.length === 0 ? 1 : 0.2 },
                    strokeWidth: { value: 2 }
                  }
                }
              },

              {
                type: 'symbol',
                from: { data: 'student' },
                encode: {
                  enter: {
                    x: { scale: 'scores', field: 'score' },
                    yc: { scale: 'domains', field: 'id', band: 0.5 },
                    tooltip: { signal: `{'${I18n.t('js.results.domains.score')}': format(datum.score, '.00%')}` }
                  },
                  update: {
                    href: [
                      { test: 'datum.url == null', value: undefined },
                      { signal: `'${window.location.origin}' + datum.url` }
                    ],
                    cursor: [
                      { test: 'datum.url == null', value: 'default' },
                      { value: 'pointer' }
                    ],
                    fill: { value: 'white' },
                    size: { value: 128 },
                    stroke: { value: '#2E2E2E' },
                    strokeOpacity: { value: 1 },
                    strokeWidth: { value: 2 }
                  },
                  hover: {
                    fill: [{ test: 'datum.url != null', value: '#2E2E2E' }, { value: 'white' }],
                    strokeOpacity: [{ test: 'datum.url != null', value: 0.12 }, { value: 1 }],
                    strokeWidth: [{ test: 'datum.url != null', value: 12 }, { value: 2 }]
                  }
                }
              },

              {
                type: 'text',
                from: { data: 'student' },
                encode: {
                  enter: {
                    align: { value: 'center' },
                    dy: { signal: '-barHeight * .33' },
                    text: { signal: "format(datum.score, '.00%')" },
                    x: { scale: 'scores', field: 'score' },
                    yc: { scale: 'domains', field: 'id', band: 0.5 },
                    fontSize: { value: 12 }
                  }
                }
              }
            ]
          }
        ]
      }
    };
  }

  function drawChart() {
    const dataTable = new google.visualization.DataTable();
    const googleChart = new google.visualization.VegaChart(chart);
    googleChart.draw(dataTable, chartOptions());
  }
  this.drawChart = debounce(drawChart, 250);

  function getChartData() {
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      success: (response) => {
        data = response;
        drawChart();
      }
    });
  }

  google.charts.load('current', { packages: ['vegachart'] });
  google.charts.setOnLoadCallback(getChartData);

  window.addEventListener('resize', this.drawChart);
}, function () {
  window.removeEventListener('resize', this.drawChart);
});
