import onmount from 'onmount';
import Rails from '@rails/ujs';
import Fuse from 'fuse.js';
import { debounce, serialize, escapeHtml } from '@modules/custom';

onmount('[data-js-search-users]', function () {
  const self = this;
  const input = this.querySelector('[data-js-search-input]');
  const reset = this.querySelector('[data-js-reset]');
  const usersList = this.querySelector('[data-js-users-list]');
  const affiliationsFilter = this.querySelectorAll('[data-js-affiliations-filter] [data-affiliation]');
  const addableUsersUrl = self.querySelector('[data-js-addable-users-url]').getAttribute('data-js-addable-users-url');
  const options = {
    isCaseSensitive: false,
    threshold: 0.1,
    minMatchCharLength: 1,
    ignoreLocation: true,
    useExtendedSearch: true,
    keys: [
      'name',
      'email',
      'student_number'
    ]
  };

  let search;

  function renderUserItem(result) {
    const userItem = `
      <li data-id='${result.id}'>
        <div class='mdc-form-field d-flex'>
          <div class='mdc-checkbox'>
            <input type='checkbox'
                   class='mdc-checkbox__native-control'
                   id='checkbox-${result.id}'
                   name='user_ids[]'
                   value='${result.id}'/>
            <div class='mdc-checkbox__background'>
              <svg class='mdc-checkbox__checkmark'
                   viewBox='0 0 24 24'>
                <path class='mdc-checkbox__checkmark-path'
                      fill='none'
                      d='M1.73,12.91 8.1,19.28 22.79,4.59'/>
              </svg>
              <div class='mdc-checkbox__mixedmark'></div>
            </div>
            <div class='mdc-checkbox__ripple'></div>
          </div>
          <label class='d-flex' style='width: 100%;' for='checkbox-${result.id}'>
            <span style='flex: 3 1 0;'class='truncate'>${[escapeHtml(result.name)]}</span>
            <span style='flex: 1 1 0;'class='truncate'>${[result.role]}</span>
            <span style='flex: 1 1 0;'class='truncate'>${[result.student_number]}</span>
            <span style='flex: 3 1 0;'class='truncate'>${[result.email]}</span>
          </label>
        </div>
      </li>`;
    usersList.insertAdjacentHTML('beforeend', userItem);
  }

  function renderUserResults(results) {
    let count = 0;

    results.some((result) => {
      if (count === 20) return true;
      if (self.querySelector(`li[data-id='${result.id}']`) !== null) return false;

      renderUserItem(result);
      count += 1;
      return false;
    });
  }

  function inputChange() {
    self.querySelectorAll('input[type=checkbox]:not(:checked)').forEach((checkbox) => {
      checkbox.closest('li').remove();
    });

    let results;
    if (input.value.trim().length > 0) {
      results = search.fuse.search(input.value).map((x) => x.item);
    } else {
      results = search.fuse._docs; // eslint-disable-line no-underscore-dangle
    }
    renderUserResults(results);
  }

  function resetSearch() {
    input.value = '';
    inputChange();
    input.focus();
  }

  function getData(filterParams = {}) {
    Rails.ajax({
      url: addableUsersUrl.toString(),
      type: 'GET',
      data: serialize(filterParams),
      dataType: 'json',
      success: (response) => {
        search = { fuse: new Fuse(response, options) };

        if (input.value) {
          inputChange();
        } else {
          renderUserResults(response);
        }

        input.addEventListener('input', debounce(inputChange, 250));
        input.addEventListener('paste', debounce(inputChange, 250));

        self.querySelector('[data-js-progress]').classList.add('d-none');
      }
    });
  }

  function handleAffiliationFilterClick() {
    document.querySelector('[data-js-users-list]').querySelectorAll('[data-id]').forEach((el) => (el).remove());
    self.querySelector('[data-js-progress]').classList.remove('d-none');
    self.querySelector('[data-js-selected-affiliation]').innerText = this.innerText;

    getData({ affiliation: this.dataset.affiliation });
  }

  getData();

  if (reset) reset.addEventListener('click', resetSearch);

  affiliationsFilter.forEach((affiliation) => {
    affiliation.addEventListener('click', handleAffiliationFilterClick);
  });
});
