/* eslint-disable */

import { removeKatexFromHtml } from '@redactor/remove-katex';

(function($R)
{
  $R.add('plugin', 'limiter', {
    translations: {
      en: {
        'maxWords': 'max words',
      }
    },
    init: function(app)
    {
      this.app = app;
      this.lang = app.lang;
      this.utils = app.utils;
      this.opts = app.opts;
      this.editor = app.editor;
      this.keycodes = app.keycodes;
      this.statusbar = app.statusbar;
    },
    // events
    onpasting: function(html)
    {
      if (!this.opts.limiter) return;

      var currentHtml = this._getHtml();
      var currentCount = this._count(currentHtml);

      var pastedCount = this._count(html);

      this.opts.input = !(currentCount + pastedCount > this.opts.limiter);
    },
    // public
    start: function()
    {
      if (!this.opts.limiter) return;
      this._validate();

      var $editor = this.editor.getElement();
      $editor.on('keydown.redactor-plugin-limiter', this._limit.bind(this));

      this.statusbar.add('maxWords', this.lang.get('maxWords') + ': ' + this.opts.limiter);
    },
    stop: function()
    {
      var $editor = this.editor.getElement();
      $editor.off('.redactor-plugin-limiter');

      this.opts.input = true;
      this.statusbar.remove('maxWords');
    },

		// private
		_limit: function(e)
		{
      var key = e.which;
      var ctrl = e.ctrlKey || e.metaKey;
      var arrows = [37, 38, 39, 40];

      if (key === this.keycodes.BACKSPACE
        || key === this.keycodes.DELETE
        || key === this.keycodes.ESC
        || key === this.keycodes.SHIFT
        || (ctrl && key === 65)
        || (ctrl && key === 88)
        || (ctrl && key === 82)
        || (ctrl && key === 116)
        || (arrows.indexOf(key) !== -1)
        )
      {
        return;
      }

      this._validate(e);
    },
    _validate: function(e)
    {
      var html = this._getHtml();
      var count = this._count(html, e);

      if (count > this.opts.limiter)
      {
        if (this.editor.caret.isEnd()) document.activeElement.blur();
        if (e) e.preventDefault();
        this.opts.input = false;
        return false;
      }
      else
      {
        this.opts.input = true;
      }
    },
    _count: function(html, e)
    {
      var count = 0;

      html = this._clean(html);

      if (e) html += (e.key.replace('Enter', ' '));
      if (html !== '')
      {
        var arrWords = html.split(/\s+/);
        count = (arrWords) ? arrWords.length : 0;
      }
      return count;
    },
    _clean: function(html)
    {
      html = removeKatexFromHtml(html);
      html = html.replace(/<\/(.*?)>/gi, ' ');
      html = html.replace(/<(.*?)>/gi, '');
      html = html.replace(/\t/gi, '');
      html = html.replace(/\n/gi, ' ');
      html = html.replace(/\r/gi, ' ');
      html = html.replace(/&nbsp;/g, ' ');
      html = html.trim();
      html = this.utils.removeInvisibleChars(html);

      return html;
    },
    _getHtml: function()
    {
      var $editor = this.editor.getElement();
      return $editor.html();
    }
  });
})(Redactor);
