/* global I18n */

function dialogActions() {
  return `
    <div class='mdc-dialog__actions'>
      <button type='button'
              class='mdc-dialog__button mdc-button'
              data-mdc-dialog-action='cancel'
              data-mdc-dialog-button-default
              data-cy='unsaved-changes-stay'>
        <span class='mdc-button__ripple'></span>
        <span class='mdc-button__label'>${I18n.t('js.changes.stay')}</span>
      </button>
      <button type='button'
              class='mdc-dialog__button mdc-button mdc-button--filled'
              data-mdc-dialog-action='accept'
              data-cy='unsaved-changes-leave'
              data-js-confirm>
        <span class='mdc-button__ripple'></span>
        <span class='mdc-button__label'>${I18n.t('js.changes.leave')}</span>
      </button>
    </div>
  `;
}

export default function dialog() {
  return `
    <div class='mdc-dialog'
         data-mdc-dialog
         data-mdc-dialog-open
         data-mdc-dialog-remove-on-close
         data-turbo-temporary
         data-controller='mount'
         data-js-unsaved-changes-warning
         data-cy='unsaved-changes-dialog'>
      <div class='mdc-dialog__container'>
        <div class='mdc-dialog__surface'
             role='alertdialog'
             aria-modal='true'
             aria-labelledby='confirm-dialog-title'
             aria-describedby='confirm-dialog-content'>
          <h2 class='mdc-dialog__title' id='confirm-dialog-title'>${I18n.t('js.changes.leave_without_saving')}</h2>
          <div class='mdc-dialog__content' id='confirm-dialog-content'>
            ${I18n.t('js.changes.unsaved_changes')}
          </div>
          ${dialogActions()}
        </div>
      </div>
      <div class='mdc-dialog__scrim'>
        <button type='button'
                class='material-icons mdc-icon-button white float-right text-semi-bold'
                aria-label='Close'
                data-mdc-dialog-action='close'>
          close
        </button>
      </div>
    </div>
  `;
}
