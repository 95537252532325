/* global Redactor */

import Rails from '@rails/ujs';

(function ($R) {
  $R.add('plugin', 'variable', {
    translations: {
      en: {
        delete: 'Delete',
        variable: 'Variable',
        'no-variables': 'No variables defined',
        'add-variable': 'Add variable'
      }
    },
    init(app) {
      this.app = app;
      this.lang = app.lang;
      this.opts = app.opts;
      this.toolbar = app.toolbar;
      this.component = app.component;
      this.insertion = app.insertion;
      this.inspector = app.inspector;
      this.selection = app.selection;

      this.opts.variables = [];
    },

    oncontextbar(e, contextbar) {
      const data = this.inspector.parse(e.target);
      if (data.isComponentType('variable')) {
        const node = data.getComponent();
        const buttons = {
          remove: {
            title: this.lang.get('delete'),
            api: 'plugin.variable.remove',
            args: node
          }
        };

        contextbar.set(e, node, buttons, 'bottom');
      }
    },

    // public
    start() {
      const buttonData = {
        title: '## variable ##'
      };
      const $button = this.toolbar.addButton('variable', buttonData);
      this.getVariables($button);

      this.app.rootElement.addEventListener('scriptUpdated', () => {
        this.getVariables($button);
      });
    },
    getVariables($button) {
      const self = this;
      const { questionId, questionHasScript } = this.app.rootElement.dataset;
      if (questionHasScript === 'false') {
        self.opts.variables = [];
        const dropdownData = self.buildDropdownSelections();
        $button.setDropdown(dropdownData);
        return;
      }

      Rails.ajax({
        url: `/code_editor/questions/${questionId}`,
        type: 'GET',
        success: (data) => {
          self.opts.variables = data;
          const dropdownData = self.buildDropdownSelections();
          $button.setDropdown(dropdownData);
        }
      });
    },
    insert(variable) {
      const name = variable;
      const $variable = this.component.create('variable');
      $variable.html(name);

      this.insertion.insertRaw($variable);
    },
    remove(node) {
      this.component.remove(node);
    },
    openCodeEditor() {
      const { questionId } = this.app.rootElement.dataset;

      Rails.ajax({
        url: `/code_editor/questions/${questionId}/edit`,
        type: 'GET',
        dataType: 'script'
      });
    },

    // private
    buildDropdownSelections() {
      const list = {};

      for (let i = 0; i < this.opts.variables.length; i += 1) {
        list[i] = {
          title: this.opts.variables[i].name,
          api: 'plugin.variable.insert',
          args: this.opts.variables[i].name
        };
      }

      list['add variable'] = {
        title: '## add-variable ##',
        api: 'plugin.variable.openCodeEditor'
      };

      return list;
    }
  });
}(Redactor));

(function ($R) {
  $R.add('class', 'variable.component', {
    mixins: ['dom', 'component'],
    init(app, el) {
      this.app = app;
      this.utils = app.utils;

      return (el && el.cmnt !== undefined) ? el : this.initialize(el);
    },

    // public
    getData() {
      return {
        type: this.getType()
      };
    },

    // private
    initialize(el) {
      const element = el || '<span>';

      this.parse(element);
      this.initWrapper();
    },
    getType() {
      const text = this.text().trim();
      return this.utils.removeInvisibleChars(text);
    },
    initWrapper() {
      this.addClass('redactor-component');
      this.attr({
        'data-redactor-type': 'variable',
        tabindex: '-1',
        contenteditable: false
      });
    }
  });
}(Redactor));
