/* global I18n */

import Rails from '@rails/ujs';

export function displayUpdatingStatus(form) {
  const element = form.querySelector('[data-js-time-ago]');
  if (!element) return;

  element.innerHTML = I18n.t('js.general.updating');
  const freezeEvent = new CustomEvent('timeago:freeze');
  element.dispatchEvent(freezeEvent);
}

export function submitOrSetPending(form) {
  if (form.getAttribute('data-saving') !== 'true') {
    Rails.fire(form, 'submit');
    form.setAttribute('data-saved', true);
  } else {
    form.setAttribute('data-pending-save', true);
  }
}
