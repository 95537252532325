import { Controller } from '@hotwired/stimulus';
import { sortable } from '@modules/sortable';

export default class extends Controller {
  static targets = ['list'];

  static values = { offset: Number };

  connect() {
    sortable(this);
  }

  updateOffset({ detail: { offset } }) {
    this.offsetValue = offset;
  }
}
