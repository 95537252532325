/* global I18n */

import onmount from 'onmount';
import buildDialog from '@components/dialog';
import { rotateImage } from '@modules/rotate-image';

onmount('[data-js-enlarge-images]', function () {
  const self = this;
  const images = this.querySelectorAll('img');
  let dialog;

  function handleRotation() {
    const rotationWrapper = self.querySelector('[data-js-rotation-wrapper]');
    const imageWrapper = dialog.querySelector('[data-js-image-wrapper]');
    const imageEl = dialog.querySelector('[data-js-image]');
    if (rotationWrapper) {
      const { rotation } = rotationWrapper.dataset;
      rotateImage(imageEl, imageWrapper, parseInt(rotation, 10));
    } else {
      imageWrapper.style = '';
      imageEl.style = '';
    }
  }

  function buildActions() {
    return `
      <button type='button'
              class='mdc-button mdc-dialog__button mdc-button--outlined'
              data-mdc-dialog-action='close'
              data-mdc-dialog-button-default>
        <div class='mdc-button__ripple'></div>
        <span class='mdc-button__label'>${I18n.t('js.general.close')}</span>
      </button>
    `;
  }

  function handleClick(e) {
    const src = e.target.getAttribute('src');
    if (!src) return;

    const dialogHtml = buildDialog(
      "<div data-js-image-wrapper><img class='enlarge-img responsive-img' data-js-image></div>",
      buildActions(),
      'data-js-enlarge-image-dialog'
    );
    document.body.insertAdjacentHTML('beforeend', dialogHtml);
    dialog = document.querySelector('[data-js-enlarge-image-dialog]');
    if (!dialog) return;

    dialog.querySelector('.mdc-dialog__surface').style.width = '80vw';
    const imageEl = dialog.querySelector('[data-js-image]');
    imageEl.setAttribute('src', src);
    handleRotation();
    onmount();
    e.preventDefault();
  }

  function initImage(element) {
    element.addEventListener('click', handleClick);
    element.style.cursor = 'zoom-in';
  }

  function addImage(e) {
    initImage(e.detail.element);
  }

  this.addEventListener('imageAdded', addImage);
  images.forEach((image) => initImage(image));
});
