import Appsignal from '@appsignal/javascript';
import { plugin as windowEventsPlugin } from '@appsignal/plugin-window-events';
import { plugin as pathDecoratorPlugin } from '@appsignal/plugin-path-decorator';
import { plugin as breadcrumbsConsolePlugin } from '@appsignal/plugin-breadcrumbs-console';
import { plugin as breadcrumbsNetworkPlugin } from '@appsignal/plugin-breadcrumbs-network';

let revision = null;
const versionEl = document.querySelector('[data-version]');
if (versionEl) {
  revision = versionEl.dataset.revision;
}

const appsignal = new Appsignal({
  key: '21c3c3f1-a795-4560-b401-9417b9f9b9c9',
  revision,
  namespace: window.location.hostname,
  ignoreErrors: [/ResizeObserver/, /Loading chunk/, /msoCommentHide/]
});

appsignal.use(windowEventsPlugin());
appsignal.use(pathDecoratorPlugin());
appsignal.use(breadcrumbsConsolePlugin());
appsignal.use(breadcrumbsNetworkPlugin());
