/* global I18n, google */

import onmount from 'onmount';
import Rails from '@rails/ujs';
import { debounce, serialize } from '@modules/custom';

onmount('[data-js-active-students]', function () {
  const chartWrapper = this.querySelector('[data-js-chart]');
  const woots = window.location.host.includes('woots');
  const { url } = this.dataset;

  function parseData(data) {
    data.forEach((row, i) => {
      const date = new Date(data[i][0]);
      data[i][0] = date;
    });
    return data;
  }

  function drawChart(data) {
    const dataTable = new google.visualization.DataTable();
    let maxValue;

    dataTable.addColumn('date', '');
    if (woots) {
      dataTable.addColumn('number', 'Woots Premium gebruiker');
      dataTable.addColumn('number', 'Woots basis gebruiker');
    } else {
      dataTable.addColumn('number', I18n.t('js.dashboard.active_students.users'));
    }
    dataTable.addRows(parseData(data));

    if (woots) {
      maxValue = Math.max(dataTable.getColumnRange(1).max + dataTable.getColumnRange(2).max, 100);
    } else {
      maxValue = Math.max(dataTable.getColumnRange(1).max, 100);
    }

    const options = {
      isStacked: true,
      backgroundColor: 'transparent',
      vAxis: {
        viewWindow: {
          min: 0,
          max: maxValue
        }
      },
      hAxis: { format: 'MM/yyyy' }
    };

    const chart = new (google.charts.Bar)(chartWrapper);
    chart.draw(dataTable, google.charts.Bar.convertOptions(options));
  }

  function getChartData() {
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      data: serialize({ woots }),
      success: (data) => drawChart(data.data)
    });
  }
  this.getChartData = debounce(getChartData, 250);

  google.charts.load('current', { packages: ['bar'] });
  google.charts.setOnLoadCallback(getChartData);

  window.addEventListener('resize', this.getChartData);
}, function () {
  window.removeEventListener('resize', this.getChartData);
});
