/* global I18n */

import onmount from 'onmount';
import { MDCDataTable } from '@material/data-table';

onmount('[data-js-table-select-all]', function () {
  const self = this;
  const table = this.closest('.mdc-data-table');
  const text = this.querySelector('[data-js-select-text]');
  const button = this.querySelector('[data-js-select-button]');
  const tableColumnCount = table.querySelector('.mdc-data-table__header-row').childElementCount;
  let totalCount = parseInt(table.getAttribute('data-items-count'), 10);

  if (!table.MDCDataTable) {
    table.MDCDataTable = new MDCDataTable(table);
    table.setAttribute('data-mdc-auto-init-state', 'initialized');
  }

  function handleSelectedAll() {
    const selectedCount = table.MDCDataTable.getSelectedRowIds().length;
    if (selectedCount === 0 || selectedCount === totalCount) return;

    text.closest('td').setAttribute('colspan', tableColumnCount);
    self.classList.remove('d-none');

    button.innerHTML = I18n.t('js.data_table.select_all_n_items', { count: totalCount });
    text.innerHTML = I18n.t('js.data_table.n_items_selected_html', { count: selectedCount });
  }

  function hideBanner() {
    self.removeAttribute('data-enabled');
    self.classList.add('d-none');
  }

  function handleLoadData(e) {
    hideBanner();
    ({ totalCount } = e.detail);
  }

  function selectAll() {
    self.setAttribute('data-enabled', true);
    button.innerHTML = I18n.t('js.data_table.undo_selection');
    text.innerHTML = I18n.t('js.data_table.all_n_items_selected_html', { count: totalCount });
  }

  function handleButtonClick() {
    if (self.dataset.enabled) {
      table.MDCDataTable.setSelectedRowIds([]);
      table.MDCDataTable.emit('MDCDataTable:unselectedAll');
      hideBanner();
    } else {
      selectAll();
    }
  }

  function handleRowSelectionChanged(e) {
    if (!e.detail.selected) hideBanner();
    if (table.MDCDataTable.getSelectedRowIds().length === table.MDCDataTable.getRows().length) handleSelectedAll();
  }

  table.MDCDataTable.listen('MDCDataTable:selectedAll', handleSelectedAll);
  table.MDCDataTable.listen('MDCDataTable:rowSelectionChanged', handleRowSelectionChanged);
  table.MDCDataTable.listen('MDCDataTable:unselectedAll', hideBanner);
  table.addEventListener('MDCDataTable:loadData', handleLoadData);
  button.addEventListener('click', handleButtonClick);
});
