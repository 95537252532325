import { Controller } from '@hotwired/stimulus';
import { MDCDrawer } from '@material/drawer';

// Connects to data-controller="drawer"
export default class extends Controller {
  static targets = ['results'];

  connect() {
    this.drawer = new MDCDrawer(this.resultsTarget);

    this.drawer.listen('MDCDrawer:opened', this.drawerOpened);
    this.drawer.listen('MDCDrawer:closed', this.drawerClosed);
  }

  disconnect() {
    this.drawer.unlisten('MDCDrawer:opened', this.drawerOpened);
    this.drawer.unlisten('MDCDrawer:closed', this.drawerClosed);
  }

  drawerOpened() {
    document.body.dataset.hotkeysDisabled = true;
  }

  drawerClosed() {
    delete document.body.dataset.hotkeysDisabled;
  }

  open() {
    this.drawer.open = true;
  }

  close() {
    this.drawer.open = false;
  }
}
