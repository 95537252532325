import Rails from '@rails/ujs';
import { serialize } from '@modules/custom';
import Sortable from 'sortablejs';

function calculatePosition(e, sortableTarget) {
  const selector = `[data-sortable-target="${sortableTarget}"]`;
  return [...e.target.parentElement.querySelectorAll(selector)].indexOf(e.item) + 1;
}

function handleUpdate(e) {
  const { sortableUrl, sortableTarget } = e.item.dataset;

  let position = calculatePosition(e, sortableTarget);
  if (this.hasOffsetValue) position += this.offsetValue;

  const params = {};
  params[sortableTarget] = { position };

  Rails.ajax({
    url: sortableUrl,
    type: 'PATCH',
    data: serialize(params),
    dataType: 'script',
    complete: (xhr) => {
      Rails.fire(document.body, 'ajax:complete', [xhr]);
    }
  });
}

export function sortable(controller) {
  const element = controller.hasListTarget ? controller.listTarget : controller.element;

  Sortable.create(element, {
    animation: 0,
    preventOnFilter: false,
    handle: '[data-sortable-handle]',
    draggable: '[data-sortable-target]',
    onUpdate: handleUpdate.bind(controller)
  });
}
