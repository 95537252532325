/* global I18n */

import Rails from '@rails/ujs';
import { Turbo } from '@hotwired/turbo-rails';
import buildDialog from '@components/dialog';
import onmount from 'onmount';

function buildActions() {
  return `
    <button type='button'
            class='mdc-button mdc-dialog__button mdc-button--outlined'
            data-mdc-dialog-action='close'
            data-mdc-dialog-button-default>
      <div class='mdc-button__ripple'></div>
      <span class='mdc-button__label'>${I18n.t('js.general.cancel')}</span>
    </button>
    <button type='button'
            class='mdc-button--danger mdc-dialog__button'
            data-mdc-dialog-action='accept'
            data-js-confirm
            data-cy="confirm-button">
      <div class='mdc-button__ripple'></div>
      <span class='mdc-button__label'>${I18n.t('js.general.confirm')}</span>
    </button>
  `;
}

function handleConfirmClick() {
  const message = this.getAttribute('data-confirm');
  delete this.dataset.confirm;
  this.click();

  if (this.hasAttribute('data-persisted-confirm')) this.setAttribute('data-confirm', message);
}

Rails.confirm = function (message, element) { // eslint-disable-line
  if (document.querySelector('[data-js-confirm-dialog]')) return false;

  document.body.insertAdjacentHTML('beforeend', buildDialog(message, buildActions(), 'data-js-confirm-dialog'));
  const confirmButton = document.querySelector('[data-js-confirm-dialog] [data-js-confirm]');
  confirmButton.addEventListener('click', handleConfirmClick.bind(element));
  onmount();
  return false;
};

Turbo.setConfirmMethod((message, element) => {
  if (document.querySelector('[data-js-confirm-dialog]')) return false;

  document.body.insertAdjacentHTML('beforeend', buildDialog(message, buildActions(), 'data-js-confirm-dialog'));
  onmount();

  const confirmButton = document.querySelector('[data-js-confirm-dialog] [data-js-confirm]');
  confirmButton.addEventListener('click', handleConfirmClick.bind(element));

  return new Promise((resolve) => {
    confirmButton.addEventListener('click', () => { resolve(true); }, { once: true });
  });
});
