/* global I18n */

import onmount from 'onmount';
import Rails from '@rails/ujs';
import snackbar from '@components/snackbar';
import { debounce } from '@modules/custom';
import { showFormErrors } from '@modules/form-responses';
import { goToNextError } from '@modules/scans/navigating';

onmount('[data-js-error-student]', function () {
  const self = this;
  const form = this.querySelector('form');
  const skip = this.querySelector('[data-js-skip]');
  const unknown = this.querySelector('[data-js-unknown]');
  const studentNumberInput = this.querySelector('[data-js-student-number]');
  const studentNameInput = this.querySelector('[data-js-student-name]');
  const studentNameResults = this.querySelector('[data-js-student-name-results]');

  function handleFormSuccess() {
    if (form.hasAttribute('data-go-to-next-error')) goToNextError(skip);
  }

  function handleFormError(e) {
    const [data] = e.detail;
    if (data.responseText.indexOf('File already exists') > 0) {
      goToNextError(skip);
      return;
    }

    showFormErrors(e);
  }

  function handleSkipSuccess(e) {
    const [, , xhr] = e.detail;

    if (xhr.status === 204) {
      snackbar(I18n.t('js.scans.no_more_errors'));
      self.MDCDialog.close();
    }
  }

  function handleUnknownChange() {
    if (this.checked) {
      studentNumberInput.removeAttribute('required');
    } else {
      studentNumberInput.setAttribute('required', true);
    }
  }

  function handleStudentNumberSearchInput() {
    const requiredLength = parseInt(this.getAttribute('maxlength'), 10);
    if (this.value.trim().length !== requiredLength) {
      studentNameInput.value = '';
      return;
    }

    const url = new URL(`${window.location.origin}${window.location.pathname}/find_by_student_number.json`);
    url.searchParams.set('student_number', this.value);

    Rails.ajax({
      url: url.toString(),
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        const textField = studentNameInput.closest('.mdc-text-field');
        textField.MDCTextField.value = data.student_name;
      },
      error: () => {
        snackbar(I18n.t('js.scans.user_not_found'));
        const textField = studentNameInput.closest('.mdc-text-field');
        textField.MDCTextField.value = '';
      }
    });
  }

  function handleStudentNameSearchFocus() {
    this.select();
    studentNameResults.classList.remove('d-none');
  }

  function handleStudentNameSearchInput() {
    if (this.value.trim().length === 0) return;

    const url = new URL(`${window.location.origin}${window.location.pathname}/find_by_student_name`);
    url.searchParams.set('student_name', this.value);

    Rails.ajax({
      url: url.toString(),
      type: 'GET',
      dataType: 'script'
    });
  }

  function handleStudentNameSearchBlur() {
    setTimeout(() => {
      studentNameResults.classList.add('d-none');
    }, 250);
  }

  function handleStudentNameClick(e) {
    const { studentNumber, studentName } = e.target.dataset;

    const nameTextField = studentNameInput.closest('.mdc-text-field');
    nameTextField.MDCTextField.value = studentName;

    const numberTextField = studentNumberInput.closest('.mdc-text-field');
    numberTextField.MDCTextField.value = studentNumber;
  }

  form.addEventListener('ajax:success', handleFormSuccess);
  form.addEventListener('ajax:error', handleFormError);
  if (skip) skip.addEventListener('ajax:success', handleSkipSuccess);
  if (unknown) unknown.addEventListener('change', handleUnknownChange);

  if (studentNumberInput) {
    studentNumberInput.addEventListener('input', debounce(handleStudentNumberSearchInput, 250));
    studentNumberInput.addEventListener('paste', debounce(handleStudentNumberSearchInput, 250));
  }
  if (studentNameInput) {
    studentNameInput.addEventListener('focus', handleStudentNameSearchFocus);
    studentNameInput.addEventListener('input', debounce(handleStudentNameSearchInput, 250));
    studentNameInput.addEventListener('paste', debounce(handleStudentNameSearchInput, 250));
    studentNameInput.addEventListener('blur', handleStudentNameSearchBlur);
  }
  if (studentNameResults) studentNameResults.addEventListener('click', handleStudentNameClick);
});
