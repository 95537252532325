import onmount from 'onmount';

onmount('[data-js-next-item-trigger]', function () {
  const nextItem = document.querySelector('[data-js-next-item]');

  function handleNextItemTriggerClick() {
    nextItem.click();
  }

  this.addEventListener('click', handleNextItemTriggerClick);
});
