/* global I18n */

import onmount from 'onmount';
import Rails from '@rails/ujs';
import Spreadsheet from 'x-data-spreadsheet';
import snackbar from '@components/snackbar';

import { displayUpdatingStatus } from '@modules/quiz/saving';
import { lockSubmission, unlockSubmission } from '@modules/quiz/locking';
import { updateActiveSubmission } from '@modules/quiz/navigating';
import { debounce } from '@modules/custom';

onmount('[data-js-response="worksheet"]', function () {
  const self = this;
  const form = this.closest('form');
  const spreadsheetEl = this.querySelector('[data-js-spreadsheet]');
  const { submissionId } = this.closest('[data-js-quiz-subquestion]').dataset;

  function handleFocus() {
    lockSubmission.call(self);

    updateActiveSubmission(submissionId);
  }

  const handleBlur = debounce(() => {
    unlockSubmission.call(self);

    if (form.getAttribute('data-saved') === 'false') {
      form.setAttribute('data-saved', true);
      displayUpdatingStatus(form);
      Rails.fire(form, 'submit');
    }
  }, 10000);

  function handleContentChanged() {
    try {
      const spreadsheetWrapper = document.createElement('div');
      new Spreadsheet(spreadsheetWrapper).loadData(JSON.parse(spreadsheetEl.value));
      form.setAttribute('data-saved', false);
      displayUpdatingStatus(form);
      handleBlur();
    } catch (TypeError) {
      snackbar(I18n.t('js.quizzes.invalid_cell_content'), true);
    }
  }

  spreadsheetEl.addEventListener('spreadsheet:cell-selected', handleFocus);
  spreadsheetEl.addEventListener('spreadsheet:change', handleContentChanged);
});
