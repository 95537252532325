/* global I18n */

import onmount from 'onmount';
import snackbar from '@components/snackbar';
import { showFormErrors } from '@modules/form-responses';
import { goToNextError } from '@modules/scans/navigating';

onmount('[data-js-error-identifier]', function () {
  const self = this;
  const form = this.querySelector('form');
  const skip = this.querySelector('[data-js-skip]');
  const del = this.querySelector('[data-js-delete-scan]');
  const rotate = this.querySelector('[data-js-rotate]');

  function handleFormSuccess(e) {
    const [data] = e.detail;
    const uploadElement = document.querySelector(`[data-js-upload][data-upload-id='${data.id}']`);
    if (uploadElement) {
      uploadElement.remove();
    }

    if (form.hasAttribute('data-go-to-next-error')) goToNextError(skip);
  }

  function handleFormError(e) {
    const [data] = e.detail;
    if (data.responseText.indexOf('already exists') > 0) {
      goToNextError(skip);
      return;
    }

    showFormErrors(e);
  }

  function handleSkipSuccess(e) {
    const [, , xhr] = e.detail;

    if (xhr.status === 204) {
      snackbar(I18n.t('js.scans.no_more_errors'));
      self.MDCDialog.close();
    }
  }

  function handleRotate() {
    if (this.checked) {
      self.querySelector('img').classList.add('rotate');
    } else {
      self.querySelector('img').classList.remove('rotate');
    }
  }

  form.addEventListener('ajax:success', handleFormSuccess);
  form.addEventListener('ajax:error', handleFormError);
  if (skip) skip.addEventListener('ajax:success', handleSkipSuccess);
  if (del) del.addEventListener('ajax:success', handleFormSuccess);

  if (rotate) rotate.addEventListener('change', handleRotate);
});
